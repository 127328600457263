import React from 'react';
import { Box, Text, SimpleGrid, Circle, Flex } from '@chakra-ui/react';

import { reviewStatusMap } from '../../../constants';
import { useParams } from '../../../hooks';
import { getReviewQuestionUrl } from '../../../utils/urlHelpers';
import { useNavigate } from 'react-router-dom';

const Section = (props) => {
  const { id, answerStatuses, closeDrawer } = props;
  const { studentAssignmentId, questionId } = useParams();
  const navigate = useNavigate();

  const handleQuestionClick = (clickedQuestionId) => {
    if (questionId !== clickedQuestionId) {
      navigate(getReviewQuestionUrl({ studentAssignmentId, questionId: clickedQuestionId }));
      closeDrawer();
    }
  };

  return (
    <Box mt={5}>
      <Flex borderBottom="1px solid #0000001F" align="center"></Flex>
      <SimpleGrid columns={4} spacing={15} mt="20px">
        {answerStatuses.map(({ questionId: id, status }, index) => (
          <Circle
            bg={reviewStatusMap[status].color}
            w="40px"
            h="40px"
            key={id}
            cursor="pointer"
            border={id === questionId ? '2px solid' : ''}
            onClick={() => handleQuestionClick(id)}
          >
            <Text>{index + 1}</Text>
          </Circle>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export { Section };

import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Center, Text } from '@chakra-ui/react';

import LoggedInUserWrapper from '../core/LoggedInUserWrapper';
import AssignmentQuestion from './AssignmentQuestion';
import { useParams, useStudentAssignmentActions, useStudentAssignmentTimer } from '../../hooks';
import { useGetStudentAssignment } from '../../queries';
import AssignmentAction from './AssignmentActions';
import { QUESTION_RESPONSE_STATUS } from '../../constants';
import { AssignmentDrawer } from './AssignmentDrawer';
import { withAuth } from '../auth';

const Assignment = () => {
  const { studentAssignmentId } = useParams();

  const {
    isLoading,
    isFetching,
    error,
    data: assignmentData,
    refetch: refetchAssignment
  } = useGetStudentAssignment(studentAssignmentId);

  const {
    isLoading: isSubmittingResponse,
    assignment,
    isLastQuestion,
    currentQuestion,
    currentQuestionIndex,
    currentQuestionResponse,
    isEndAssignmentLoading,
    isBookmarkLoading,
    bookmarkAction,
    onSkip,
    onEndTest,
    onSaveAndNext,
    visitQuestion
  } = useStudentAssignmentActions({ assignmentData, refetchAssignment });

  React.useEffect(() => {
    if (
      currentQuestionResponse?.status &&
      currentQuestionResponse.status === QUESTION_RESPONSE_STATUS.NOT_VISITED
    ) {
      // TODO solve the problem
      // visitQuestion(currentQuestionResponse);
    }
  }, [currentQuestionResponse, visitQuestion]);

  const { countdown, pause, start } = useStudentAssignmentTimer({
    isLoading: isFetching,
    onEndTest,
    timeRemaining: assignmentData?.duration?.remaining,
    studentAssignmentId
  });

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: assignment?.name,
        renderActions: () => (
          <AssignmentAction
            showPauseButton={assignmentData?.duration?.remaining !== null}
            onEndTest={onEndTest}
            pauseTimer={pause}
            startTimer={start}
          />
        ),
        time: assignmentData?.duration?.remaining !== null ? countdown : null
      }}
    >
      {!assignment && !isFetching ? (
        <Center w="100%" h="100%">
          <Alert status='error' variant={'left-accent'} w={300}>
            <AlertIcon />
            <Box>
              <AlertTitle>Error!</AlertTitle>
              <AlertDescription>
                Invalid Assignment ID
              </AlertDescription>
            </Box>
          </Alert>
        </Center>
      ) : (
        <>
          <AssignmentQuestion
            question={currentQuestion}
            questionIndex={currentQuestionIndex}
            isLastQuestion={isLastQuestion}
            questionResponse={currentQuestionResponse}
            isBookmarkLoading={isBookmarkLoading}
            onSkip={onSkip}
            isLoading={isLoading || isSubmittingResponse || isEndAssignmentLoading || isFetching}
            onSaveAndNext={onSaveAndNext}
            bookmarkAction={bookmarkAction}
          />
          <AssignmentDrawer studentResponse={assignmentData?.studentResponse} />
        </>
      )}
    </LoggedInUserWrapper>
  );
};

const AssignmentWithAuth = withAuth(Assignment);
export default AssignmentWithAuth;

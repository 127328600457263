import React from 'react';
import { Flex, Text, Box, Divider } from '@chakra-ui/react';
import { Button, FullPageLoader } from '../shared';
import { useParams } from '../../hooks';
import MCQReview from './MCQReview';
import FIBReview from './FIBReview';

const ReviewQuestionAnswer = (props) => {
  const {
    question = {},
    onPrevious,
    onNext,
    questionIndex,
    isCorrectlyAnswered,
    questionResponse = {},
    isLoading,
    isFirstQuestion,
    isLastQuestion
  } = props;
  const { id, score, type } = question;
  const { questionId } = useParams();

  if (isLoading) {
    return <FullPageLoader />;
  }

  return id ? (
    <Flex
      w="100%"
      flexDirection="column"
      justify="start"
      align="center"
      mt="2"
      mb="50"
      key={questionId}
    >
      <Flex w="100%" justify="start" align="center" py={4}>
        <Text fontSize="sm" color={'#8954BA'}>
          Question ID - {questionIndex + 1} {type !== 'fib' && <>(Score - {score} points)</>}
        </Text>
      </Flex>
      <Divider />
      <Box mt="2" w="100%" className="katex-question" position="relative">
        {type === 'mcq' && (
          <MCQReview
            question={question}
            response={questionResponse}
            isCorrectlyAnswered={isCorrectlyAnswered}
            key={question?.id}
          />
        )}
        {type === 'fib' && (
          <FIBReview
            question={question}
            response={questionResponse}
            isCorrectlyAnswered={isCorrectlyAnswered}
            key={question?.id}
          />
        )}
        {/* Footer */}
        <Box mt="4" position="fixed" bottom={0} right="30px" h="70px">
          <Button mx="4" gap="4" px="6" onClick={onPrevious} isDisabled={isFirstQuestion}>
            Previous
          </Button>
          <Button mx="4" gap="4" px="6" onClick={onNext} isDisabled={isLastQuestion}>
            Next
          </Button>
        </Box>
      </Box>
    </Flex>
  ) : null;
};

export default ReviewQuestionAnswer;

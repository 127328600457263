'use client';
import React, { useCallback, useState } from 'react';
import {
  Box,
  Heading,
  HStack,
  Image,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  IconButton,
  Button,
  Center,
  useToast
} from '@chakra-ui/react';
import { uniqBy } from 'lodash';
import { FiPlus } from 'react-icons/fi';
import { TiDelete } from 'react-icons/ti';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useDeleteBadgeFromModal, useGetAllBadges, useUpdateBadgesFromModal } from '../../../queries';
import { useAuth } from '../../../hooks';

function BadgeItem({badgeItem, onBadgeSelection, onDeleteBadge }) {
  const { name, badge, isSelected } = badgeItem;
  
  return (
    <GridItem
      key={badge}
      bg={isSelected ? '#1A4D8E' : '#F2F2F2'}
      color={isSelected ? '#fff' : '#222'}
      cursor="pointer"
    >
      <HStack justifyContent="space-between">
        <Box w={'100%'} textAlign="center">
            <Text isTruncated fontSize={{sm: 'sm', md: 'md'}}>{name}</Text>
        </Box>
        <IconButton
          borderLeft={'1px solid #ddd'}
          bg={isSelected ? '#0c3d6e' : '#ddd'}
          _hover={isSelected ? '#0c3d6e' : '#ddd'}
          rounded={0}
          icon={!isSelected ? <FiPlus /> : <TiDelete size={22} color={isSelected ? '#fff' : '#ddd'} />}
          onClick={() =>
            !isSelected ? onBadgeSelection(badge) : onDeleteBadge(badge)
          }
        />
      </HStack>
    </GridItem>
  );
}

export default function BadgeModal({ isBadgeOpen, onBadgeClose }) {
  const [userBadges, setUserBadges] = useState([]);
  const { mutate: updateBadges } = useUpdateBadgesFromModal();
  const { mutate: deleteBadge } = useDeleteBadgeFromModal();
  const { data: allBadges, isLoading } = useGetAllBadges();
  const { user } = useAuth();
  const toast = useToast();
  
 
  React.useEffect(() => {
    if (!isLoading) {
      const uniqueBadges = uniqBy(allBadges, 'name')      
      setUserBadges(uniqueBadges)
    }
  }, [isLoading, allBadges])

  const onAddBadge = useCallback((badge) => {
    const badges = userBadges.map((badgeItem) => {
      if (badgeItem.badge === badge) {
        badgeItem.isSelected = true;
      }
      return badgeItem
    });
    setUserBadges(badges)
  }, [userBadges]);

  const onDeleteBadge = useCallback(
    async (badge) => {
      deleteBadge(badge)
      const badges = userBadges.map((badgeItem) => {
        if (badgeItem.badge === badge) {
          badgeItem.isSelected = false;
        }
        return badgeItem
      });
      setUserBadges(badges)
    },
    [userBadges, deleteBadge]
  );

  const onContinue = useCallback(async () => {
    const badges = userBadges.filter(badgeItem => badgeItem.isSelected).map(bd => bd.badge);    
    updateBadges(badges.toString());
    onBadgeClose();
    toast({
      title: 'Badges updated',
      description: "We've updated the badge list",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }, [updateBadges, toast, onBadgeClose, userBadges]);

  return (
    <Modal
      blockScrollOnMount={false}
      isCentered
      isOpen={isBadgeOpen}
      onClose={onBadgeClose}
      size={{ base: 'xl', md: '3xl' }}
    >
      <ModalOverlay />
      <ModalContent mx={5}>
        <ModalCloseButton />
        <ModalBody mt={3} py={{ sm: 5, md: 10 }}>
          <VStack spacing={{ sm: 2, md: 5 }}>
            <Box>
              <Image src="/logo.png" width={240} alt="logo" />
            </Box>
            <Heading fontWeight={600} size={'md'}>
              {!!user?.displayName ? `Hi ${user.displayName}, ` : ''}Choose your interests
            </Heading>
            <Box w={{ sm: '100%', md: '80%' }}>
              <Box mt={10} maxH={200} overflowY={'auto'}>
                <Grid templateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }} gap={4}>
                  {userBadges &&
                    userBadges.map((badgeItem) => (
                      <BadgeItem
                        key={badgeItem.badge}
                        badgeItem={badgeItem}
                        onBadgeSelection={onAddBadge}
                        onDeleteBadge={onDeleteBadge}
                      />
                    ))}
                </Grid>
              </Box>
            </Box>
          </VStack>
          <Center>
            <Button
              _hover={{ bg: '#1A4D8E', opacity: 0.8 }}
              bg="#1A4D8E"
              color={'#fff'}
              size={{ sm: 'md', md: 'lg' }}
              mt={10}
              isDisabled={!Reflect.ownKeys(userBadges).length}
              onClick={onContinue}
              rightIcon={<IoIosArrowRoundForward size={30} />}
            >
              Continue
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { createContext, useContext, useEffect } from 'react';
import { useIdToken, useSignOut } from 'react-firebase-hooks/auth';

import { useFirebase } from './useFirebase';
import { SUBSCRIPTION_PLANS } from '../constants';

const AuthContext = createContext({ user: null });

const BASE_EMPTY = {};

const AuthProvider = ({ children }) => {
  const { auth, googleAuthProvider } = useFirebase();
  const [userSubscriptionPlan, setUserSubscriptionPlan] = React.useState(SUBSCRIPTION_PLANS.FREE);
  const [signOut, isLogoutLoading] = useSignOut(auth || BASE_EMPTY);

  const updateUser = useCallback(
    async (user) => {
      if (!user) {
        console.info(`no token found`);
        return;
      }
      setUser(user);
      console.info(`token changed: `, user.accessToken);
      const decodedToken = await user.getIdTokenResult();
      const newSubscription = decodedToken?.claims?.studentSubscription;
      if (newSubscription !== undefined) {
        setUserSubscriptionPlan((currentPlan) => {
          if (currentPlan !== newSubscription) {
            return newSubscription;
          }
          return currentPlan;
        });
      }
    },
    [setUserSubscriptionPlan]
  );

  const [userInfo, isLoginLoading, error] = useIdToken(auth || BASE_EMPTY, {
    onUserChanged: updateUser
  });
  const [user, setUser] = useState(userInfo);

  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);

  useEffect(() => {
    return auth?.onIdTokenChanged(updateUser);
  }, [auth, user, updateUser]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      console.info(`refreshing token`);
      const user = auth?.currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, [auth]);

  const value = useMemo(() => {
    return {
      user,
      auth,
      signOut,
      token: user?.accessToken,
      error,
      userSubscriptionPlan,
      googleAuthProvider,
      loading: isLoginLoading || isLogoutLoading
    };
  }, [
    auth,
    error,
    googleAuthProvider,
    isLoginLoading,
    isLogoutLoading,
    signOut,
    user,
    userSubscriptionPlan
  ]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthContext');
  }
  return context;
};

export { useAuth, AuthProvider };

import React from 'react';
import { Center } from '@chakra-ui/react';

const Banner = (props) => {
  const { message } = props;

  if (!message) {
    return null;
  }

  return (
    <Center w="100%" h="48px" backgroundColor="#5D38DB" color="#FFFFFF" mr={-4} px={4}>
      {message}
    </Center>
  );
};

export { Banner };

import React from 'react';
import { Box, Circle, Flex, Text, SimpleGrid } from '@chakra-ui/react';

import { REVIEW_STATUS, reviewStatusMap } from '../../../constants';

const Summary = (props) => {
  const { answerStatuses } = props;

  const summaryStatusMap = structuredClone(reviewStatusMap);
  Object.keys(summaryStatusMap).map((status) => {
    summaryStatusMap[status].count = 0;
  });

  answerStatuses.forEach(({ status }) => {
    summaryStatusMap[status].count++;
  });

  const statusArray = [
    summaryStatusMap[REVIEW_STATUS.CORRECT],
    summaryStatusMap[REVIEW_STATUS.PARTIAL],
    summaryStatusMap[REVIEW_STATUS.INCORRECT],
    summaryStatusMap[REVIEW_STATUS.SKIPPED]
  ];

  return (
    <SimpleGrid columns={2} columnGap={6} rowGap={1}>
      {statusArray.map(({ color, uiText, count }, index) => (
        <Box mt={4} key={`${uiText}-${index}`}>
          <Flex justify="center" mb={-1}>
            <Circle h="33px" w="33px" backgroundColor={color}>
              <Text fontWeight={700} fontSize="xl">
                {count}
              </Text>
            </Circle>
          </Flex>
          <Flex
            backgroundColor="#d9d9d9"
            h="28px"
            borderRadius="16px"
            alignItems="center"
            justify="center"
          >
            <Text fontWeight={700} fontSize="xs">
              {uiText}
            </Text>
          </Flex>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export { Summary };

import format from 'date-fns/format';

export const msToTime = (duration) => {
  let milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return {
    minutes,
    seconds,
    hours
  };
};

export const isDatePassed = (timestamp) => {
  try {
    const date = new Date(timestamp);
    const time = date.getTime();
    return time < Date.now();
  } catch (error) {
    // Handle invalid or malformed timestamp strings
    console.error(error.message);
    return false;
  }
};

export const timestampToDate = (timestamp, stringFormat = 'dd MMM, yyyy') => {
  try {
    // Convert the timestamp to a Date object using fromUnixTime
    const dateString = format(new Date(timestamp), stringFormat);
    // Return the string
    return dateString;
  } catch (error) {
    // Handle invalid or malformed timestamp strings
    console.error(error.message);
    return null;
  }
};

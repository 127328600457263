import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { withAuth } from '../../auth';
import { deleteUser } from 'firebase/auth';
import { useAuth } from '../../../hooks';
import LoggedInUserWrapper from '../../core/LoggedInUserWrapper';
import { useNavigate } from 'react-router-dom';

const DeleteUser = () => {
  const { auth } = useAuth();

  const user = auth.currentUser;

  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onCloseConfirmModal
  } = useDisclosure();

  const cancelRef = React.useRef();
  const toast = useToast();
  const navigate = useNavigate();

  const deleteConfirm = useCallback(() => {
    //Deleting user via firebase auth API
    deleteUser(user)
      .then(() => {
        toast({
          title: 'Account deleted.',
          description: 'Your account has been successfully deleted.',
          status: 'success',
          duration: 10000,
          isClosable: true
        });

        navigate('/login');
      })
      .catch((err) => {
        toast({
          title: 'Error deleting account!',
          description: `${err}`,
          status: 'error',
          duration: 10000,
          isClosable: true
        });
      });
  }, [navigate, toast, user]);

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: (
          <Image
            src="/logo.png"
            width={240}
            alt="logo"
            onClick={() => navigate('/')}
            cursor="pointer"
          />
        ),
        renderActions: () => null
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        <AlertDialog
          isOpen={isConfirmModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCloseConfirmModal}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Account
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You cannot undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseConfirmModal}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={deleteConfirm} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Flex flexDirection="column" w={{ base: 'unset', lg: '40%' }} m={{ base: 2, lg: 5 }}>
          <Text mb={2}>
            Deleting your account results in all of your user related infromation being deleted from
            Classwise.
          </Text>
          <Text mb={2} fontWeight="bold">
            Proceed with caution! This cannot be reversed!
          </Text>
          <Alert status="error" flexDirection="column" alignItems="flex-start">
            <Flex>
              <AlertIcon />
              <AlertTitle>Are you sure you want to delete your account?</AlertTitle>
            </Flex>
            <Button mt={2} colorScheme="red" onClick={onConfirmModalOpen}>
              DELETE ACCOUNT
            </Button>
          </Alert>
        </Flex>
      </Flex>
    </LoggedInUserWrapper>
  );
};

const DeleteUserWithAuth = withAuth(DeleteUser);

export default DeleteUserWithAuth;

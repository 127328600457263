import React from 'react';
import ChatMain from '../shared/Chat/ChatMain';
import { STUDENT_CHAT_TYPES } from '../../constants/socket-constants';
import { withAuth } from '../auth';

function AskMeQuestionChat() {
  return <ChatMain title="Ask Me Questions" chatType={STUDENT_CHAT_TYPES.ASK_ME_QUESTIONS} />;
}

const AskMeQuestionChatWithAuth = withAuth(AskMeQuestionChat);

export default AskMeQuestionChatWithAuth;

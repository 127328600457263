import { Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const FIBInput = ({ blankId, initialValue, onChange, isDisabled, isError }) => {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const handleChange = (event) => setValue(event.target.value);

  useEffect(() => {
    if (!touched) return;
    onChange(blankId, value);
  }, [blankId, onChange, touched, value]);

  return (
    <Input
      display="flex"
      width="unset"
      value={value}
      onChange={handleChange}
      isDisabled={isDisabled}
      placeholder="Fill in blank"
      mx={2}
      border="transparent"
      boxShadow="0 0 0 1px rgba(93, 56, 219, 0.8)"
      onBlur={() => setTouched(true)}
      _hover={{
        border: 'transparent',
        boxShadow: '0 0 0 1px rgba(93, 56, 219, 0.8)'
      }}
      _focusVisible={{
        border: 'transparent',
        boxShadow: '0 0 0 2px rgba(93, 56, 219, 0.8)'
      }}
      isInvalid={isError || (touched && value === '')}
    />
  );
};

export default FIBInput;

import { isObject, toLower } from 'lodash';
import { REVIEW_STATUS } from '../constants';

export const getQuestionFromQuestionId = (assignment, questionId) => {
  return assignment?.questions?.find((q) => q.id === questionId);
};

export const getIsCorrectlyAnswered = (question, response) => {
  const type = question.type;
  switch (type) {
    case 'mcq':
      const { answer } = response;
      return question.options.find(({ isCorrect }) => isCorrect).id === answer?.[0];
    case 'fib':
      const answers = response?.answers;
      const blankConfig = question?.fibMetadata?.blankConfig;

      // If the blank config does not exist or answers are not submitted (could be due to skipping of question) then don't process this
      if (!blankConfig || !answers || !isObject(answers)) return false;

      // We will update the correct counter only if all blanks are correct
      return Object.entries(answers).every(([key, value]) => {
        const blank = blankConfig[key];
        return toLower(blank.answers?.[0]) === toLower(value?.[0]);
      });
    default:
      return false;
  }
};

export const getAnswerStatus = (question, response) => {
  const type = question.type;
  switch (type) {
    case 'mcq':
      const { answer } = response;
      const isCorrect = question.options.find(({ isCorrect }) => isCorrect).id === answer?.[0];
      return isCorrect ? REVIEW_STATUS.CORRECT : REVIEW_STATUS.INCORRECT;
    case 'fib':
      const answers = response?.answers;
      const blankConfig = question?.fibMetadata?.blankConfig;

      // If the blank config does not exist or answers are not submitted (could be due to skipping of question) then don't process this
      if (!blankConfig || !answers || !isObject(answers)) return false;

      let hasCorrect = false;
      let hasIncorrect = false;

      // We will update the correct counter only if all blanks are correct
      Object.entries(answers).forEach(([key, value]) => {
        const blank = blankConfig[key];
        if (toLower(blank.answers?.[0]) === toLower(value?.[0])) {
          hasCorrect = true;
        } else {
          hasIncorrect = true;
        }
      });

      if (hasCorrect && hasIncorrect) {
        return REVIEW_STATUS.PARTIAL;
      } else if (hasCorrect) {
        return REVIEW_STATUS.CORRECT;
      } else {
        return REVIEW_STATUS.INCORRECT;
      }

    default:
      return false;
  }
};

const getAssignmentDetails = ({ assignmentData, questionId }) => {
  if (!assignmentData) {
    return {};
  }
  const { assignment, studentResponse } = assignmentData;
  const currentQuestion = getQuestionFromQuestionId(assignment, questionId);
  const currentQuestionResponse = studentResponse.find((q) => q.questionId === questionId);

  // Finding the next & previous question
  let nextQuestionId = null;
  let previousQuestionId = null;
  let isLastQuestion = false;
  let isFirstQuestion = false;
  const currentQuestionIndex = studentResponse.findIndex(
    (question) => question.questionId === questionId
  );

  if (currentQuestionIndex < assignment.questions.length - 1) {
    nextQuestionId = studentResponse[currentQuestionIndex + 1].questionId;
  } else {
    isLastQuestion = true;
  }

  if (currentQuestionIndex > 0) {
    previousQuestionId = studentResponse[currentQuestionIndex - 1].questionId;
  } else {
    isFirstQuestion = true;
  }

  return {
    isFirstQuestion,
    isLastQuestion,
    currentQuestion,
    currentQuestionIndex,
    currentQuestionResponse,
    nextQuestionId,
    previousQuestionId,
    name: assignment?.name
  };
};

export { getAssignmentDetails };

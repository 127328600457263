import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  useDisclosure,
  Stack,
  Image
} from '@chakra-ui/react';

import { ConfirmationModal, Button } from '../../shared';

const PauseTest = (props) => {
  const { startTimer, pauseTimer } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const pauseHandler = () => {
    pauseTimer();
    onOpen();
  };

  const resumeHandler = () => {
    startTimer();
    onClose();
  };

  return (
    <>
      <Image
        src="/assets/images/pause-assignment-small.svg"
        display={{ base: 'inline-flex', md: 'none' }}
        alt="Assignment Result"
        mr="3"
        h="22px"
        onClick={pauseHandler}
      />
      <Button
        display={{ base: 'none', md: 'inline-flex' }}
        gap="4"
        px="6"
        mr="3"
        onClick={pauseHandler}
      >
        Pause Test
      </Button>
      <Modal isOpen={isOpen} isCentered size={{ base: 'xs', md: 'sm' }}>
        <ModalOverlay />
        <ModalContent py="8">
          <ModalBody>
            <Stack>
              <Image src="/assets/images/result-tick.svg" alt="Assignment Result" mb="5" h="98px" />
              <Stack justifyContent="center" alignItems="center" spacing="0">
                <div>Your assignment has been paused.</div>
                <div>Try to come back soon to</div>
                <div>resume assignment</div>
              </Stack>
            </Stack>
          </ModalBody>
          <Center mt="4">
            <Button onClick={resumeHandler}>Resume</Button>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};

export { PauseTest };

import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Center
} from '@chakra-ui/react';

import { Button } from '.';

const ConfirmationModal = (props) => {
  const { isOpen, onClose, onAction, primaryButtonText, secondaryButtonText, modalText } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'xs', md: 'md' }}>
      <ModalOverlay />
      <ModalContent py="8">
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <Text>{modalText}</Text>
          </Center>
        </ModalBody>

        <Center mt="4">
          <Button onClick={onAction}>{primaryButtonText}</Button>
          <Button buttonType="secondary" onClick={onClose} ml={3}>
            {secondaryButtonText}
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export { ConfirmationModal };

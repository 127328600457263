import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getConsumptionDetails,
  getCurrentSubscription,
  getSubscriptionManagementDetails,
  getSubscriptionPrices,
  refreshSubscription
} from '../api';
import { useAuth } from '../hooks';
import { SUBSCRIPTION_PLANS } from '../constants';

export const useSubscription = ({ token, transactionId, isEnabled = true }) =>
  useQuery({
    queryKey: ['get-subscription', transactionId],
    queryFn: getCurrentSubscription.bind(null, { token, transactionId }),
    enabled: isEnabled,
    retry: 5
  });

export const usePrices = ({ token }) =>
  useQuery({
    queryKey: ['subscription-prices'],
    queryFn: getSubscriptionPrices.bind(null, { token }),
    staleTime: Infinity
  });

export const useConsumption = () => {
  const { token, userSubscriptionPlan } = useAuth();
  const queryData = useQuery({
    queryKey: ['consumption'],
    queryFn: getConsumptionDetails.bind(null, { token }),
    enabled: userSubscriptionPlan === SUBSCRIPTION_PLANS.FREE
  });
  return {
    ...queryData,
    isCreditExhauted:
      userSubscriptionPlan === SUBSCRIPTION_PLANS.FREE && queryData?.data?.credits <= 0
  };
};

export const useSubscriptionManagement = () => {
  const { token, userSubscriptionPlan } = useAuth();
  return useQuery({
    queryKey: ['management'],
    queryFn: getSubscriptionManagementDetails.bind(null, { token }),
    enabled: userSubscriptionPlan === SUBSCRIPTION_PLANS.PRO,
    staleTime: Infinity
  });
};

export const useRefreshSubscription = () => {
  return useMutation({
    mutationFn: (token) => refreshSubscription({ token })
  });
};

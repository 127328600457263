import { initializePaddle } from '@paddle/paddle-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { useUserLocation } from '../queries';
import { getPaymentSuccessUrl } from '../utils';

export const usePaddleCheckout = () => {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data: locationData } = useUserLocation();

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({
      environment: import.meta.env.NEXT_PUBLIC_PADDLE_ENVIRONMENT,
      token: import.meta.env.NEXT_PUBLIC_PADDLE_TOKEN,
      eventCallback: (data) => {
        if (data.name == 'checkout.completed') {
          const {
            data: { transaction_id }
          } = data;
          setTimeout(() => {
            user.getIdToken(true);
            navigate(getPaymentSuccessUrl(transaction_id));
            paddle?.Checkout.close();
          }, 1000);
        }
      }
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [navigate, paddle?.Checkout, user]);

  // Callback to open a checkout
  const openCheckout = ({ priceId }) => {
    paddle?.Checkout.open({
      items: [{ priceId: priceId, quantity: 1 }],
      customer: {
        email: user?.email || '',
        address: {
          countryCode: locationData?.country_code,
          postalCode: locationData?.postal,
          region: locationData?.city,
          city: locationData?.city
        }
      }
    });
  };

  return {
    openCheckout
  };
};

import React from 'react';
import {
  Box,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure
} from '@chakra-ui/react';

import { Button } from '../shared';

const AssignmentResult = (props) => {
  const { buttonText, result, isLoading, reviewHandler, revealSolutions } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { answered = '-', timeTaken = '-', totalScore = '-', accuracy = '-' } = result || {};
  const resultKpis = [
    {
      image: '/assets/images/result-score.svg',
      label: 'Score',
      value: `${totalScore} points`
    },
    {
      image: '/assets/images/result-solved.svg',
      label: 'Solves',
      value: `${answered} questions`
    },
    {
      image: '/assets/images/result-accuracy.svg',
      label: 'Accuracy',
      value: `${accuracy}%`
    },
    {
      image: '/assets/images/result-speed.svg',
      label: 'Speed (minutes)',
      value: `${timeTaken}`
    }
  ];

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'xs', md: 'md' }}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <Center mt={5}>
                <Flex direction="column" alignItems="center">
                  <Image src="/assets/images/result-tick.svg" alt="Assignment Result" />
                  <Text mt={5}>Good work!</Text>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} my={5}>
                    {resultKpis.map((kpi, index) => (
                      <Flex key={`${index}-${kpi.label}`}>
                        <Image src={kpi.image} alt={kpi.label} w="52px" h="52px" />
                        <Flex
                          ml={3}
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="center"
                        >
                          <Text color="grey" fontSize="sm">
                            {kpi.label}
                          </Text>
                          <Text fontSize="sm" mt={0} fontWeight={600}>
                            {kpi.value}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </SimpleGrid>
                  {revealSolutions && (
                    <Button mt={5} mb={2} onClick={reviewHandler}>
                      Review
                    </Button>
                  )}
                </Flex>
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button mt={4} loadingText="Getting the test ready" onClick={onOpen}>
        {buttonText}
      </Button>
    </Box>
  );
};

export default AssignmentResult;

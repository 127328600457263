import { get, put, post, patch } from '../utils/APIClient';

export const getStudentAssignment = async ({ studentAssignmentId, token }) => {
  const result = await get({ url: `student-assignments/${studentAssignmentId}`, token });
  return result;
};

export const updateResponseForStudentAssignment = async ({ assignmentId, data, token }) => {
  return put({ url: `student-assignments/${assignmentId}/response`, data, token });
};

export const createStudentAssignment = async ({ assignmentId, token }) => {
  const result = await post({ url: `student-assignments`, data: { assignmentId }, token });
  return result;
};

export const startStudentAssignment = async ({ studentAssignmentId, token }) => {
  const result = await put({ url: `student-assignments/${studentAssignmentId}/start`, token });
  return result;
};

export const updateTimeRemaining = async ({ studentAssignmentId, token, data }) => {
  const result = await put({ url: `student-assignments/${studentAssignmentId}/time`, token, data });
  return result;
};

export const endStudentAssignment = async ({ studentAssignmentId, token }) => {
  const result = await patch({ url: `student-assignments/${studentAssignmentId}/end`, token });
  return result;
};

export const getStudentAssignmentResult = async ({ studentAssignmentId, token }) => {
  const result = await get({ url: `student-assignments/${studentAssignmentId}/result`, token });
  return result;
};

export const getPracticeQuestionTopics = async ({ token, badges }) => {
  const query = badges ? `?badges=${badges}` : '';
  const result = await get({ url: `study-material${query}`, token });
  return result;
}

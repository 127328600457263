import React from 'react';
import { Flex, Text, Box, Spinner } from '@chakra-ui/react';
import Select from 'react-select';
import { AiOutlineHome } from 'react-icons/ai';
import { groupBy } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';

import { useGetPracticeQuestionTopics, useUpdateBadges } from '../../../queries';
import LoggedInUserWrapper from '../../core/LoggedInUserWrapper';

const ChatTopicSelection = (props) => {
  const { title, navigationPath } = props;
  const navigate = useNavigate();
  const { data, isLoading } = useGetPracticeQuestionTopics();
  const { mutate: updateBadges } = useUpdateBadges();

  React.useEffect(() => {
    updateBadges();
  }, [updateBadges]);

  const onChangeTopic = (topic) => {
    navigate(`/${navigationPath}/${topic.value}`);
  };

  const groupTopicBySubject = groupBy(data, 'subject');
  const topicsOptions =
    !isLoading && data
      ? Reflect.ownKeys(groupTopicBySubject).map((subject) => {
          return {
            label: subject,
            options: groupTopicBySubject[subject].map((topic) => {
              return {
                label: topic?.topic,
                value: topic?._id
              };
            })
          };
        })
      : [];

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: (
          <Link to={'/'}>
            <AiOutlineHome size={25} />
          </Link>
        ),
        pageTitle: title,
        renderActions: () => null
      }}
    >
      <Box py={10}>
        <Flex justifyContent={'center'} h={'30vh'} flexDirection={'column'} alignItems="center">
          <Text fontSize={{ base: 'md', md: 'xl' }} fontWeight={'bold'}>
            {`Great! Let's start the quiz. `}
          </Text>
          <Text fontSize={{ base: 'md', md: 'xl' }}>Select a topic below to get started</Text>
          <Box my={5}>
            {topicsOptions.length > 0 ? (
              <Select
                className="basic-single"
                classNamePrefix="topic-select"
                placeholder="Select a Topic to Practice"
                isClearable
                defaultMenuIsOpen
                isSearchable
                options={topicsOptions}
                onChange={onChangeTopic}
                styles={{ width: 300 }}
              />
            ) : (
              <Spinner />
            )}
          </Box>
        </Flex>
      </Box>
    </LoggedInUserWrapper>
  );
};

export default ChatTopicSelection;

import { usePrices, useUserLocation } from '../queries';
import { getCurrencySymbol } from '../utils';
import { useAuth } from './useAuth';

export const usePricingPlans = () => {
  const { token } = useAuth();
  const {
    data: locationData,
    isLoading: isLocationDataLoading,
    isError: iseLocationDataError
  } = useUserLocation();
  const { data: prices, isLoading: isPricesLoading, isError: isPricesError } = usePrices({ token });
  if (isLocationDataLoading || isPricesLoading) {
    return {
      isLoading: true,
      prices: []
    };
  }

  if (iseLocationDataError || isPricesError) {
    return {
      isLoading: false,
      prices: []
    };
  }

  const filteredPrices = prices.map((price) => {
    const { currency, country } = locationData;
    const {
      id,
      name,
      unitPrice,
      unitPriceOverrides,
      popular,
      info,
      features,
      billingCycle: { interval }
    } = price;
    let finalCurrency = unitPrice.currencyCode;
    let finalAmount = unitPrice.amount;

    // Check if the user is not from the default country (India)
    if (currency !== unitPrice.currencyCode) {
      for (let priceOverride of unitPriceOverrides) {
        const { unitPrice, countryCodes } = priceOverride;
        if (countryCodes.includes(country)) {
          finalCurrency = unitPrice.currencyCode;
          finalAmount = unitPrice.amount;
          break;
        }
      }
    }

    return {
      id,
      name,
      price: finalAmount / 100,
      currency: getCurrencySymbol(finalCurrency),
      popular: !!popular,
      interval,
      features: features ? features.split(':') : [],
      info
    };
  });

  return {
    isLoading: false,
    prices: filteredPrices
  };
};

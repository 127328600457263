import React from 'react';
import { Button, Flex, FormControl, Input, Icon } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { useApp, useAuth } from '../../../hooks';
import { useConsumption } from '../../../queries';

function ChatInput({ setMessages, sendStudentMessage }) {
  const { user } = useAuth();
  const { showPlans } = useApp();
  const { isCreditExhauted } = useConsumption();
  const [chatInput, setChatInput] = React.useState('');

  const sendMessage = async (e) => {
    e.preventDefault();
    if (isCreditExhauted) {
      showPlans();
      return;
    }
    const chatId = uuidv4();
    if (chatInput !== '')
      setMessages((prevState) => [
        ...prevState,
        ...[
          {
            id: chatId,
            message: chatInput,
            isSentByUser: true,
            photoURL: user?.photoURL,
            displayName: user?.displayName
          }
        ]
      ]);
    sendStudentMessage(chatInput);
    setChatInput('');
    setTimeout(() => {
      const ele = document.getElementById(chatId);
      ele.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const handleChange = (e) => {
    setChatInput(e.target.value);
  };

  return (
    <Flex
      direction="row"
      position="fixed"
      w={{ base: '100%', md: '60%' }}
      bottom={{ base: 0, md: 3 }}
      bg={'#fff'}
    >
      <FormControl
        py={3}
        px={5}
        m={3}
        zIndex={3}
        as="form"
        display="flex"
        alignItems="center"
        shadow={'xl'}
        rounded={'full'}
        borderWidth={'1px'}
        borderColor={'#eee'}
      >
        <Input
          size="md"
          value={chatInput}
          onChange={handleChange}
          variant={'unstyled'}
          placeholder="Write your response here"
        />
        <Button
          variant={'unstyled'}
          size="lg"
          type="submit"
          onClick={sendMessage}
          mt={2}
          verticalAlign={'middle'}
        >
          <Icon as={RiSendPlane2Fill} color={'#3369ff'} fontSize="4xl" />
        </Button>
      </FormControl>
    </Flex>
  );
}

export default ChatInput;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../hooks';
import { getStudentAssignment } from '../../../../api';
import { withAuth } from '../../../auth/withAuth';
import { useEffect } from 'react';

const AssignmentHome = () => {
  const { token } = useAuth();

  const { studentAssignmentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssignmentAndRedirect = async () => {
      const { studentResponse } = await getStudentAssignment({ studentAssignmentId, token });
      const questionId = studentResponse[0]?.questionId;
      if (questionId) {
        navigate(`/assignment/${studentAssignmentId}/${questionId}`);
      }
    };

    fetchAssignmentAndRedirect();
  }, [navigate, studentAssignmentId, token]);

  return <div>Fetching Assignment details...</div>;
};

export default withAuth(AssignmentHome);

import React from 'react';
import AssignmentEndScreen from '../../AssignmentEndScreen/AssignmentEndScreen';
import { useParams } from 'react-router-dom';
import { useGetStudentAssignment } from '../../../queries';

export const AssignmentEndPage = () => {
  const { studentAssignmentId } = useParams();

  const {
    isLoading,
    isFetching,
    data: assignmentData
  } = useGetStudentAssignment(studentAssignmentId);

  console.log('assignment', assignmentData);

  if (isLoading || isFetching) {
    return <>Loading...</>;
  }

  const {
    status,
    studentResponse,
    assignment: {
      thankYouScreen,
      name,
      endButtonText,
      settings: { revealSolutions },
      questions
    }
  } = assignmentData;

  return (
    <AssignmentEndScreen
      name={name}
      status={status}
      endButtonText={endButtonText}
      thankYouScreen={thankYouScreen}
      revealSolutions={revealSolutions}
      firstQuestionId={studentResponse[0]?.questionId}
      totalQuestions={questions?.length || 0}
    />
  );
};

import React from 'react';
import { Flex } from '@chakra-ui/react';
import { EndTest } from './EndTest';
import { PauseTest } from './PauseTest';

const AssignmentAction = (props) => {
  const { onEndTest, pauseTimer, startTimer, showPauseButton } = props;
  return (
    <Flex justifyContent="center" alignItems="center">
      {showPauseButton && <PauseTest pauseTimer={pauseTimer} startTimer={startTimer} />}
      <EndTest onEndTest={onEndTest} />
    </Flex>
  );
};

export default AssignmentAction;

import React, { useCallback, useMemo } from 'react';
import { Flex, Text, Box, Divider } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { useParams } from '../../../hooks';
import { Button, FullPageLoader } from '../../shared';
import { BookmarkButton } from './BookmarkButton';
import { shuffle } from '../../../utils';
import { EndTest } from '../AssignmentActions/EndTest';
import McqQuestionBody from './McqQuestionBody';
import FIBQuestionBody from './FIBQuestionBody';

const AssignmentQuestion = (props) => {
  const {
    question = {},
    questionIndex,
    onSaveAndNext,
    isLastQuestion,
    onSkip,
    bookmarkAction,
    questionResponse = {},
    isBookmarkLoading,
    isLoading
  } = props;
  const { id, score, type } = question;
  const { isBookmarked } = questionResponse;
  const { questionId } = useParams();

  const options = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return shuffle(question.options);
  }, [question.options, isLoading]);

  const {
    register,
    handleSubmit: formSubmit,
    formState: { errors },
    getValues,
    setValue
  } = useForm();

  const QuestionComponent = useCallback(
    (props) => {
      if (type === 'mcq') {
        return <McqQuestionBody {...props} />;
      } else if (type === 'fib') {
        return <FIBQuestionBody {...props} />;
      }
    },
    [type]
  );

  if (isLoading) {
    return <FullPageLoader />;
  }

  const handleSubmit = (response) => {
    // console.log(response);
    onSaveAndNext({ ...response, questionResponse });
  };

  return id ? (
    <form onSubmit={formSubmit(handleSubmit)}>
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        justify="space-between"
        align="center"
        mt="2"
        key={questionId}
      >
        <Box overflow="scroll" w="100%">
          <Flex w="100%" justify="start" align="center" py={4}>
            <Text fontSize="sm" color={'#8954BA'}>
              Q ID - {questionIndex + 1} ({score} points)
            </Text>
            <BookmarkButton
              isLoading={isBookmarkLoading}
              isBookmarked={isBookmarked}
              bookmarkAction={() => bookmarkAction(questionResponse)}
            />
          </Flex>
          <Divider />
          {/* Question Content */}
          <QuestionComponent
            {...question}
            {...questionResponse}
            errors={errors}
            options={options}
            register={register}
            setValue={setValue}
            key={questionId}
          />
        </Box>
        {/* Footer */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-end' }}
          w="100%"
          position={{ base: 'relative', md: 'fixed' }}
          bottom={0}
          right="30px"
          h="70px"
        >
          {isLastQuestion ? (
            <EndTest
              buttonText="Save and End Test"
              onEndTest={() =>
                onSaveAndNext({ ...getValues(), questionResponse, shouldEndTest: true })
              }
            />
          ) : (
            <Button
              mx="4"
              gap="4"
              px="6"
              isLoading={isLoading}
              onClick={() => onSkip(questionResponse)}
              isDisabled={isLastQuestion}
            >
              Skip Question
            </Button>
          )}
          <Button mx="4" gap="4" px="6" type="submit" isLoading={isLoading}>
            {isLastQuestion ? 'Save' : 'Save and Next'}
          </Button>
        </Box>
      </Flex>
    </form>
  ) : null;
};

export default AssignmentQuestion;

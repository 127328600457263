import React, { useState } from 'react';
import { getStudentAssignment } from '../../../api';
import { withAuth } from '../../auth';
import { useAuth, useParams } from '../../../hooks';
import AssignmentReview from '../../AssignmentReview/AssignmentReview';

const AssignmentReviewMain = () => {
  const { studentAssignmentId } = useParams();
  const { token } = useAuth();
  const [studentResponse, setStudentRespone] = useState();

  React.useEffect(() => {
    const fetchAssignment = async () => {
      const { studentResponse } = await getStudentAssignment({
        token,
        studentAssignmentId
      });
      setStudentRespone(studentResponse);
    };

    fetchAssignment();
  }, [studentAssignmentId, token]);

  return (
    <>{studentResponse ? <AssignmentReview studentResponse={studentResponse} /> : 'Loading...'}</>
  );
};

const AssignmentReviewMainWithAuth = withAuth(AssignmentReviewMain);

export default AssignmentReviewMainWithAuth;

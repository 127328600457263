import React from 'react';
import { Flex, Box, Center, Circle, useDisclosure } from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react';

import { Summary } from './Summary';
import { Section } from './Section';

const AssignmentReviewDrawer = (props) => {
  const { answerStatuses } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  if (!answerStatuses) {
    return null;
  }
  return (
    <>
      <Flex
        flexDirection="column"
        w="28px"
        h="100%"
        backgroundColor="white"
        position="absolute"
        right="0"
        top="0"
        justifyContent="center"
        alignItems="center"
        boxShadow="-6px 0 6px #d9d9d9"
      >
        <Circle
          w="40px"
          h="40px"
          border="1px solid #C9CDDA"
          bg="white"
          ref={btnRef}
          onClick={onOpen}
          cursor="pointer"
          ml={-8}
          boxShadow="0 0 6px #d9d9d9"
        >
          <Center>{'<<'}</Center>
        </Circle>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        id="drawer-main"
        isFullHeight={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {/* <DrawerHeader>Questions</DrawerHeader> */}

          <DrawerBody>
            <Box ml={5}>
              <Summary answerStatuses={answerStatuses} />
              <Section id={1} answerStatuses={answerStatuses} closeDrawer={onClose} />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export { AssignmentReviewDrawer };

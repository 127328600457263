import React from 'react';
import { Tag } from '@chakra-ui/react';
import { QUESTION_RESPONSE_STATUS } from '../../constants';

const TAG_CONFIG = {
  CORRECT: {
    color: 'green',
    text: 'Wohoo! Good work — you are right!'
  },
  PARTIAL: {
    text: 'Oops! You missed some answers - check out the solutions!',
    color: 'orange'
  },
  SKIPPED: {
    text: 'Seems you skipped it — check out the solution!',
    color: 'gray'
  },
  INCORRECT: {
    text: 'Oops! You missed this — check out the solution!',
    color: 'red'
  }
};

export const QuestionResultTag = (props) => {
  const { isCorrectlyAnswered, status, cssProps, isPartial } = props;

  let inferredState = TAG_CONFIG.CORRECT;
  if (isPartial) {
    inferredState = TAG_CONFIG.PARTIAL;
  } else if (!isCorrectlyAnswered) {
    if (status !== QUESTION_RESPONSE_STATUS.ANSWERED) {
      inferredState = TAG_CONFIG.SKIPPED;
    } else {
      inferredState = TAG_CONFIG.INCORRECT;
    }
  }

  return (
    <Tag
      borderRadius="10px"
      w="300px"
      mr="3"
      p={3}
      justifyContent="center"
      colorScheme={inferredState.color}
      {...cssProps}
    >
      {inferredState.text}
    </Tag>
  );
};

import React from 'react';
import { Flex, Box, useDisclosure } from '@chakra-ui/react';
import AppHeader from '../../../app/HeaderComponent/AppHeader';
import { Banner } from '../../../app/Banner';
import BadgeModal from '../../../shared/BadgeModal';
import { useGetAllBadges } from '../../../../queries';
import { useParams } from '../../../../hooks';
import UpgradeBanner from '../../../app/HeaderComponent/UpgradeBanner';

const AppCommonLayout = ({ children, headerProps, bannerProps }) => {
  const { data: userBadges, isLoading } = useGetAllBadges();
  const { isOpen: isBadgeOpen, onOpen: onBadgeOpen, onClose: onBadgeClose } = useDisclosure();
  const { isLoggedIn } = useParams();

  React.useEffect(() => {
    if (userBadges && isLoggedIn) {
      const userSelectedBadges = userBadges.filter((badge) => badge.isSelected);
      userSelectedBadges.length === 0 && onBadgeOpen();
    }
  }, [userBadges, onBadgeOpen, isLoggedIn]);

  return (
    <Flex h="100vh" w="100%" direction="column" overflow="hidden">
      <AppHeader {...headerProps} onBadgeOpen={onBadgeOpen} />
      <UpgradeBanner />
      <Banner {...bannerProps} />
      <Box
        w="100%"
        pl={{ base: 0, md: 8 }}
        pr={{ base: 0, md: 8 }}
        pb={2}
        flexGrow={1}
        overflow="auto"
      >
        {children}
      </Box>
      {!isLoading && isBadgeOpen && (
        <BadgeModal isBadgeOpen={isBadgeOpen} onBadgeClose={onBadgeClose} />
      )}
    </Flex>
  );
};

export default AppCommonLayout;

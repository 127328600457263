import axios from 'axios';
import { USER_ROLES } from '../constants';
import { post, put, get } from '../utils/APIClient';

export const setStudentRole = ({ token }) =>
  post({ url: 'users/role', token, data: { role: USER_ROLES.STUDENT } });

export const createStudent = async ({ token, student }) =>
  post({ url: 'users', token, data: student });

export const getAllBadges = async ({ token }) => get({ url: 'badges', token });

export const updateBadges = async ({ token, data }) => {
  if (!data.badges) {
    return '';
  }
  const result = await put({ url: 'users', token, data });
  return result;
};

export const getUserLocationInfo = async () => {
  const response = await axios.get('https://ipapi.co/json');
  return response.data;
};

export const deleteUserBadge = async ({ token, data }) => {
  if (!data.badge) {
    return '';
  }
  const result = await put({ url: 'users/badge', token, data });
  return result;
};

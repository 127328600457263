import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { SUBSCRIPTION_PLANS } from '../../../constants';
import { useConsumption } from '../../../queries';
import { useApp, useAuth } from '../../../hooks';

const UpgradeBanner = () => {
  const { data, isLoading } = useConsumption();
  const { userSubscriptionPlan } = useAuth();
  const { showPlans } = useApp();
  if (isLoading || userSubscriptionPlan === SUBSCRIPTION_PLANS.PRO || data?.credits > 1) {
    return null;
  }

  let bannerMessage = "You're about to run out of daily free credits (1 left).";
  if (data?.credits === 0) {
    bannerMessage = 'You have exhausted your daily free credit limit.';
  }

  return (
    <Flex
      h="50px"
      minHeight="50px"
      w="100vw"
      backgroundColor="#5D38DB"
      color="#FFFFFF"
      left="0"
      alignItems="center"
      justifyContent="center"
      flexDirection={{ md: 'row', base: 'column' }}
      onClick={showPlans}
    >
      <Text fontSize={{ md: 'md', base: 'sm' }}>{bannerMessage}</Text>
      <Text
        ml={{ md: 2, base: 0 }}
        fontSize={{ md: 'md', base: 'sm' }}
        color="yellow"
        as="u"
        cursor="pointer"
      >
        Upgrade Now
      </Text>
    </Flex>
  );
};

export default UpgradeBanner;

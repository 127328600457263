import React from 'react';
import { Flex, Text, Input, useToast } from '@chakra-ui/react';
import { AiOutlineHome } from 'react-icons/ai';

import LoggedInUserWrapper from './core/LoggedInUserWrapper';
import { Button } from './shared';
import { useAuth } from '../hooks';
import { isValidClasswiseAssignmentURL } from '../utils/helper';
import { getAssignmentStartUrl } from '../utils/urlHelpers';
import { Link, useNavigate } from 'react-router-dom';

const JoinPage = () => {
  const { user } = useAuth();
  const toast = useToast();
  const linkRef = React.useRef('');
  const navigate = useNavigate();

  const handleJoin = () => {
    const link = linkRef.current.value;
    if (!isValidClasswiseAssignmentURL(link)) {
      toast({
        title: 'Validation Error',
        description: 'Please enter a validation Assignment link shared with you.',
        status: 'error',
        isClosable: true
      });
    } else {
      const assignmentId = link.split('/').pop();
      navigate(getAssignmentStartUrl({ assignmentId }));
    }
  };

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: (
          <Link to={'/'}>
            <AiOutlineHome size={25} />
          </Link>
        ),
        renderActions: () => null
      }}
    >
      <Flex w="100%" h="100%" direction="column" justify="start" align="center" mt="8" mb="50">
        <Flex
          w="100%"
          justify="center"
          align="center"
          flexDirection="column"
          fontSize={{ base: '2xl', md: '7xl' }}
        >
          <Text fontWeight={600}>Welcome, {user?.displayName?.split(' ')?.[0]}</Text>
          <Flex w={{ base: '95%', md: '80%' }} mt="6" flexDirection={{ base: 'column', md: 'row' }}>
            <Input
              placeholder="Enter the assignment link shared by your teacher"
              borderRadius="0"
              ref={linkRef}
              fontSize={{ base: 'sm', md: '1xl' }}
            />
            <Button px="9" onClick={handleJoin} mt={{ base: 5, md: 0 }}>
              Join
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </LoggedInUserWrapper>
  );
};

export default JoinPage;

import React, { useMemo, useRef } from 'react';
import { Flex, Box, Text, Stack, Divider, OrderedList, ListItem } from '@chakra-ui/react';
import { QuestionResultTag } from './QuestionResultTag';
import { ElementsRenderer } from '../shared';
import parse, { domToReact } from 'html-react-parser';
import { toLower } from 'lodash';
import FIBBlankReview from './FIBBlankReview';

const FIBReview = ({ question, response, isCorrectlyAnswered }) => {
  const { elements, score, solution } = question;
  const { answers, status } = response;

  const fibText = elements?.[0]?.html;
  const blankConfig = question?.fibMetadata?.blankConfig;

  const isPartial = useRef(false);

  const answersReview = useMemo(() => {
    let userScore = 0;
    const reviews = Object.entries(answers).reduce((review, [key, value]) => {
      const isCorrect = toLower(blankConfig?.[key]?.answers?.[0]) === toLower(value?.[0]);
      // If one of the answers is right, but not all of them are
      if (isCorrect && !isCorrectlyAnswered) {
        isPartial.current = true;
      }
      if (isCorrect) userScore += blankConfig?.[key]?.score || 0;
      review[key] = {
        answers: blankConfig?.[key]?.answers,
        value,
        isCorrect
      };
      return review;
    }, {});

    return {
      reviews,
      userScore
    };
  }, [answers, blankConfig, isCorrectlyAnswered]);

  const parseReplaceOptions = useMemo(
    () => ({
      replace: (domNode) => {
        if (!domNode) return;

        // FIB elements contain an outer P tag
        switch (domNode.name) {
          case 'p':
            return (
              <Flex wrap="wrap" alignItems="center">
                {domToReact(domNode?.children, parseReplaceOptions)}
              </Flex>
            );
          case 'ol':
            return (
              <OrderedList pl={3}>{domToReact(domNode?.children, parseReplaceOptions)}</OrderedList>
            );
          case 'li':
            return (
              <ListItem my={2}>
                <Flex alignItems="center">
                  {domToReact(domNode?.children, parseReplaceOptions)}
                </Flex>
              </ListItem>
            );
        }

        // Inside these P tags, there is either a text node or a blank span embed
        if (domNode.type === 'text') {
          return <Text>{domNode.data}</Text>;
        }

        // In place editing of the input
        if (domNode.attribs && domNode.attribs['data-blank_id']) {
          const blankId = domNode.attribs['data-blank_id'];
          return <FIBBlankReview {...answersReview?.reviews[blankId]} />;
        }
      }
    }),
    [answersReview]
  );

  const parsedHtmlContent = useMemo(() => {
    return parse(fibText, parseReplaceOptions);
  }, [fibText, parseReplaceOptions]);

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <Text color={'#686868'}>Fill in the Blanks</Text>
          <Text color={'#686868'} fontSize="small" fontStyle="italic">
            Your Score - {answersReview?.userScore} / {score}
          </Text>
        </Flex>
        <QuestionResultTag
          isCorrectlyAnswered={isCorrectlyAnswered}
          status={status}
          cssProps={{
            display: { base: 'none', md: 'inline-flex' }
          }}
          isPartial={isPartial.current}
        />
      </Flex>
      <Divider my={6} />
      <Box my="2" w="100%">
        <Box fontSize="lg">{parsedHtmlContent}</Box>
      </Box>
      <QuestionResultTag
        isCorrectlyAnswered={isCorrectlyAnswered}
        status={status}
        cssProps={{
          display: { base: 'block', md: 'none' },
          mb: 4
        }}
        isPartial={isPartial.current}
      />
      {/* Solution */}
      <Divider mt={6} />
      <Stack mt={3}>
        <Text>Detail Solution</Text>
        <Box color="grey">
          <ElementsRenderer elements={solution?.elements} />
        </Box>
      </Stack>
    </Box>
  );
};

export default FIBReview;

import React from 'react';
import ReactDOM from 'react-dom/client';

import 'suneditor/dist/css/suneditor.min.css';
import { App } from './App';

export const DEFAULT_EMPTY = {};

// eslint-disable-next-line react/prop-types
ReactDOM.createRoot(document.getElementById('root')).render(<App />);



import React from "react";
import rehypeRaw from 'rehype-raw'
import rehypeExternalLinks from 'rehype-external-links'
import ReactMarkdown from "react-markdown";
import remarkYoutube from 'remark-youtube';
import MathJax from "react-mathjax";
import RemarkMathPlugin from "remark-math";
import { BlockMath, InlineMath } from "react-katex";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";


export default function MarkdownWithKatex(props) {
  const newProps = {
    ...props,
    remarkPlugins: [RemarkMathPlugin, remarkYoutube],
    rehypePlugins: [rehypeRaw, rehypeKatex, [rehypeExternalLinks, {target: '_blank'}]],
    components: {
      ...props.renderers,
      math: ({ value }) => <BlockMath>{value}</BlockMath>,
      inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>
    }
  };
  return (
    <MathJax.Provider input="tex">
      <ReactMarkdown {...newProps} />
    </MathJax.Provider>
  );
}

import { useState } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const useFirebase = () => {
  const [auth, setAuth] = useState(null);
  const CLIENT_CONFIG = {
    apiKey: import.meta.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: import.meta.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: import.meta.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
  };

  if (typeof window !== 'undefined' && getApps().length === 0) {
    initializeApp(CLIENT_CONFIG);
    window.firebase = auth;
  }

  if (typeof window !== 'undefined' && !auth) {
    setAuth(getAuth());
  }

  return { auth };
};

export { useFirebase };

import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';

const FullPageLoader = () => {
  return (
    <Center w="100vw" h="100vh">
      <Spinner />
    </Center>
  );
};

export { FullPageLoader };

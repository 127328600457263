import { STUDENTASSIGNMENT_STATUS } from '../constants';
import { isDatePassed, timestampToDate } from '../utils';

export const useStartScreen = ({ assignmentData, createData }) => {
  if (!assignmentData) {
    return {};
  }

  const {
    duration,
    name,
    welcomeScreen: { elements },
    startButtonText,
    startDate,
    dueDate
  } = assignmentData;

  let bannerMessage = null;
  let allowStart = true;
  let isAssignmentCompleted = false;
  let studentAssignmentId = null;
  if (dueDate && isDatePassed(dueDate)) {
    const dueDateString = timestampToDate(dueDate);
    bannerMessage = `This assignment is past its Due Date: ${dueDateString}. It was required to be completed before ${dueDateString}. You may reach out to your teacher for an extension.`;
    allowStart = false;
  } else if (startDate && !isDatePassed(startDate)) {
    const startDateString = timestampToDate(startDate);
    bannerMessage = `The start date for this assignment is ${startDateString}. You can start this only after ${startDateString}`;
    allowStart = false;
  }

  if (createData?.status === STUDENTASSIGNMENT_STATUS.COMPLETED) {
    isAssignmentCompleted = true;
    studentAssignmentId = createData._id;
  }

  return { bannerMessage, allowStart, isAssignmentCompleted, studentAssignmentId };
};

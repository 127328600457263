export const appConfig = {
  apiBaseUrl: import.meta.env.NEXT_PUBLIC_API_BASE_URL || '/',
  wsURL: import.meta.env.NEXT_PUBLIC_SOCKET_BASE_URL
};

export const QUESTION_RESPONSE_STATUS = {
  ANSWERED: 'answered',
  SKIPPED: 'skipped',
  BOOKMARKED: 'bookmarked',
  NOT_VISITED: 'notVisited',
  VISITED: 'visited'
};

export const REVIEW_STATUS = {
  CORRECT: 'CORRECT',
  PARTIAL: 'PARTIAL',
  INCORRECT: 'INCORRECT',
  SKIPPED: 'SKIPPED'
};

export const questionStatusMap = {};
questionStatusMap[QUESTION_RESPONSE_STATUS.ANSWERED] = {
  color: '#C0E281',
  uiText: 'Answered'
};
questionStatusMap[QUESTION_RESPONSE_STATUS.SKIPPED] = {
  color: '#EED68A',
  uiText: 'Skipped'
};
questionStatusMap[QUESTION_RESPONSE_STATUS.BOOKMARKED] = {
  color: '#EF8888',
  uiText: 'Bookmarked'
};
questionStatusMap[QUESTION_RESPONSE_STATUS.NOT_VISITED] = {
  color: '#0000004D',
  uiText: 'Not Visited'
};

export const reviewStatusMap = {};
reviewStatusMap[REVIEW_STATUS.CORRECT] = {
  color: '#C0E281',
  uiText: 'Correct'
};
reviewStatusMap[REVIEW_STATUS.PARTIAL] = {
  color: '#F3BB51',
  uiText: 'Partial'
};
reviewStatusMap[REVIEW_STATUS.SKIPPED] = {
  color: '#EED68A',
  uiText: 'Skipped'
};
reviewStatusMap[REVIEW_STATUS.INCORRECT] = {
  color: '#EF8888',
  uiText: 'Incorrect'
};

export const USER_ROLES = {
  ADMIN: 'admin',
  STUDENT: 'student',
  TEACHER: 'teacher'
};

export const STUDENTASSIGNMENT_STATUS = {
  YET_TO_START: 'YET_TO_START',
  STARTED: 'STARTED',
  PAUSED: 'PAUSED',
  COMPLETED: 'COMPLETED'
};

export const TERMS_OF_USE_URL = 'https://getclasswise.com/Terms_Of_Use';
export const PRIVACY_URL = 'https://getclasswise.com/Privacy_Policy';
export const PRACTICE_QUESTIONS_URL = '/practice-questions';
export const ASK_ME_QUESTIONS_URL = '/ask-me-questions';
export const LEARN_A_TOPIC_URL = '/learn-a-new-topic';

export const homeMenu = [
  {
    title: '⛹️‍♂️ Practice Questions',
    subTitle: 'Challenge yourself with Adaptive Quizzes',
    link: PRACTICE_QUESTIONS_URL
  },
  {
    title: '❓ Ask me Questions',
    subTitle: 'Get immediate responses to your queries',
    link: ASK_ME_QUESTIONS_URL
  },
  {
    title: '🧑‍🏫 Learn a new Topic',
    subTitle: 'Dive into detailed topic explanations',
    link: LEARN_A_TOPIC_URL
  }
];

export const practiceTopics = [
  'Chemical Reactions and Equations',
  'Acids, Bases and Salts',
  'Metals and Non-metals',
  'Carbon and its Compounds',
  'Life Processes',
  'Control and Coordination',
]

export const SUBSCRIPTION_PLANS = {
  FREE: 'FREE_STUDENT',
  PRO: 'PRO_STUDENT'
};

export const themeColorHex = '#1A4D8E';

import React from 'react';
import { Flex, Text, Box, Image } from '@chakra-ui/react';
import LoggedInUserWrapper from '../../components/core/LoggedInUserWrapper';
import { homeMenu } from '../../constants';
import { useAuth, useParams } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useUpdateBadges } from '../../queries';

const Home = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { badges } = useParams();
  const { mutate: updateBadges } = useUpdateBadges();

  React.useEffect(() => {
    if (badges) {
      updateBadges();
    }
  }, [updateBadges, badges]);

  const onNavigateToPage = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: <Image src="/logo.png" width={240} alt="logo" onClick={() => navigate('/')} />,
        renderActions: () => null
      }}
    >
      <Flex justifyContent={'center'} py={20} flexDirection={'column'} alignItems="center">
        <Box pb={3} pt={5}>
          <Image src="/vertical-logo.png" width={206} alt="vertical logo" />
        </Box>
        <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'}>
          Welcome, {user?.displayName?.split(' ')?.[0]}
        </Text>
        <Text fontSize={{ base: 'xl', md: '2xl' }}>What would like to do today</Text>

        <Flex justify="center" my={5} flexDirection={{ base: 'column', md: 'row' }}>
          {homeMenu.map((menu) => {
            return (
              <Box
                transition={'0.3s ease'}
                border={'1px'}
                cursor={'pointer'}
                borderColor={'#B2BEDA'}
                rounded={3}
                py={3}
                px={5}
                key={menu.title}
                m={2}
                onClick={() => onNavigateToPage(menu.link)}
                _hover={{ shadow: 'md', bg: '#f1f1f166' }}
              >
                <Text fontWeight={'bold'} fontSize={{ base: 'md', md: 'lg' }} opacity={0.8}>
                  {menu.title}
                </Text>
                <Text color={'#000'} opacity={0.5}>
                  {menu.subTitle}
                </Text>
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </LoggedInUserWrapper>
  );
};

export default Home;

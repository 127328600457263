import { Box } from '@chakra-ui/react';
import React from 'react';
import AppCommonLayout from './AppCommonLayout';

export default function LoggedInUserWrapper({ children, ...otherProps }) {
  return (
    <Box overflow="hidden" w="100%" h="100vh">
      <AppCommonLayout {...otherProps}>{children}</AppCommonLayout>
    </Box>
  );
}

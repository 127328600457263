import React from 'react';
import ChatMain from '../shared/Chat/ChatMain';
import { STUDENT_CHAT_TYPES } from '../../constants/socket-constants';
import { withAuth } from '../auth';

function PracticeQuestionChat() {
  return <ChatMain title="Practice Questions" chatType={STUDENT_CHAT_TYPES.PRACTISE_QUESTION} />;
}

const PracticeQuestionChatWithAuth = withAuth(PracticeQuestionChat);

export default PracticeQuestionChatWithAuth;

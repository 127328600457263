import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const LabelValue = (props) => {
  const { label, value } = props;
  return (
    <Box mt="8px">
      <Text as="b" fontSize="12px">
        {label}
      </Text>
      <Text fontSize="12px">{value}</Text>
    </Box>
  );
};

export default LabelValue;

import React from 'react';
import { Box } from '@chakra-ui/react';
import { HTMLRenderer } from './HTMLRenderer';

const ElementsRenderer = (props) => {
  const { elements } = props;
  if (elements?.length > 0) {
    return (
      <Box>
        {elements.map((e) => (
          <HTMLRenderer element={e} key={e.id} />
        ))}
      </Box>
    );
  }
  return null;
};

export { ElementsRenderer };

export const scrollIntoElement = (elementId) => {
  if (elementId) {
    setTimeout(() => {
      const ele = document.getElementById(elementId);
      if (ele) {
        ele.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1);
  }
};

import { useDisclosure } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';

export const AppContext = React.createContext(null);

// eslint-disable-next-line react/prop-types
export default function AppProvider({ children, value }) {
  const [showCanvasHeader, setShowCanvasHeader] = useState(false);
  const { isOpen: isPricingPlansVisible, onClose: hidePlans, onOpen: showPlans } = useDisclosure();
  const newValue = {
    ...value,
    showPlans,
    hidePlans,
    showCanvasHeader,
    setShowCanvasHeader,
    isPricingPlansVisible
  };
  return <AppContext.Provider value={newValue}>{children}</AppContext.Provider>;
}

const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp can only be used inside AppProvider');
  }
  return context;
};

export { useApp, AppProvider };

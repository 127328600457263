import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getHomePageUrl } from '../utils';
import { ASK_ME_QUESTIONS_URL, LEARN_A_TOPIC_URL, PRACTICE_QUESTIONS_URL } from '../constants';

export const useRedirectToHome = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const redirectToHome = () => {
    toast({
      title: 'Invalid URL',
      description: 'Page not found!',
      status: 'error',
      duration: 2000,
      isClosable: true
    });
    navigate(getHomePageUrl());
  };
  return { redirectToHome };
};

export const useIsMobileDevice = () => {
  const [isMobileDevice] = useMediaQuery('(max-width: 800px)');
  return isMobileDevice;
};

export const useIsInChatPages = () => {
  const { pathname } = useLocation();
  const { chatTopicId } = useParams();
  if (
    !!chatTopicId &&
    (pathname.startsWith(PRACTICE_QUESTIONS_URL) ||
      pathname.startsWith(LEARN_A_TOPIC_URL) ||
      pathname.startsWith(ASK_ME_QUESTIONS_URL))
  ) {
    return true;
  }
  return false;
};

// export const useUpdateToken = () => {
//   const {user} = useAuth(); 
//   const [isEnabled, setIsEnabled] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const timeoutRef = useRef(null);
//   const updateToken = useCallback(() => {
//     setIsEnabled(true);
//     setIsLoading(true);
//   }, [setIsEnabled, setIsLoading]);
  
//   useEffect(() => {
//     if (isEnabled) {
//       timeoutRef.current = setTimeout(() => {
//         user.getIdToken(true);
//       }, 3000);
//     }
//   }, [isEnabled, user]);

//   return {
//     updateToken
//   }
// }
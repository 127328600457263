import React from 'react';
import { Box, Divider, Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { ElementsRenderer } from '../../shared';

const McqQuestionBody = ({ elements, answer, options, register, errors }) => {
  return (
    <Box mt="5" w="100%" className="katex-question">
      <Box>
        <Text color={'#686868'}>Multiple Choice Question</Text>
        <Flex my={2} w="95%" flexDir={'column'}>
          <Box fontSize="lg">
            <ElementsRenderer elements={elements} />
          </Box>
        </Flex>
        {/* <input type="text" hidden value={id} {...register('questionId')} /> */}
        <Flex w="100%" flexDirection={'column'}>
          <Divider />
          <RadioGroup defaultValue={answer[0]}>
            <Stack direction="column">
              <Text mt={3} mb={1}>
                Select all that apply:
              </Text>
              <Divider />
              {options.map(({ id, elements }) => {
                return (
                  <span id={id} key={id}>
                    <Radio
                      colorScheme="purple"
                      py="2"
                      value={id}
                      {...register('answer', { required: 'Please select an option' })}
                    >
                      <ElementsRenderer elements={elements} />
                    </Radio>
                    <Divider />
                  </span>
                );
              })}
            </Stack>
          </RadioGroup>
        </Flex>
      </Box>

      <Text mt={2} color="red">
        {errors.answer?.message}
      </Text>
    </Box>
  );
};

export default McqQuestionBody;

import axios from 'axios';

import { appConfig } from '../constants';

const getHeaders = (token) => {
  return {
    Authorization: `Bearer ${token}`
  };
};

export const post = ({ url, data, token }) => {
  return axios.post(`${appConfig.apiBaseUrl}${url}`, data, { headers: getHeaders(token) });
};

export const get = async ({ url, token }) => {
  const result = await axios.get(`${appConfig.apiBaseUrl}${url}`, { headers: getHeaders(token) });
  return result.data;
};

export const patch = async ({ url, data, token }) => {
  const result = await axios.patch(`${appConfig.apiBaseUrl}${url}`, data, {
    headers: getHeaders(token)
  });
  return result.data;
};

export const put = async ({ url, data, token }) => {
  return axios.put(`${appConfig.apiBaseUrl}${url}`, data, { headers: getHeaders(token) });
};

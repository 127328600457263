import React, { useEffect } from 'react';
import { Box, Center, Stack, Text, useToast } from '@chakra-ui/react';
import { getHomePageUrl, getReviewQuestionUrl } from '../../utils/urlHelpers';
import { STUDENTASSIGNMENT_STATUS } from '../../constants';
import { useStudentAssignmentResult } from '../../queries';
import { useParams } from '../../hooks';
import LoggedInUserWrapper from '../core/LoggedInUserWrapper';
import AssignmentResult from '../AssignmentResult/AssignmentResult';
import { ElementsRenderer } from '../shared';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';

const AssignmentEndScreen = (props) => {
  const { status, thankYouScreen, name, endButtonText, firstQuestionId, revealSolutions } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const { studentAssignmentId } = useParams();
  const { data: result, isLoading } = useStudentAssignmentResult(studentAssignmentId);

  useEffect(() => {
    if (status && status !== STUDENTASSIGNMENT_STATUS.COMPLETED) {
      toast({
        title: 'Invalid URL',
        description: 'Page not found!',
        status: 'error',
        duration: 2000,
        isClosable: true
      });
      navigate(getHomePageUrl());
    }
  }, [navigate, status, toast]);

  const endText = endButtonText || 'See Result';

  const reviewHandler = () => {
    navigate(getReviewQuestionUrl({ studentAssignmentId, questionId: firstQuestionId }));
  };

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: (
          <Link to={'/'}>
            <AiOutlineHome size={25} />
          </Link>
        ),
        pageTitle: <Box textAlign={'center'}>{name}</Box>,
        renderActions: () => null
      }}
    >
      <Center>
        <Stack w="90%" mt={3}>
          <ElementsRenderer elements={thankYouScreen?.elements} />
          <Center>
            <AssignmentResult
              buttonText={endText}
              revealSolutions={revealSolutions}
              result={result}
              isLoading={isLoading}
              reviewHandler={reviewHandler}
            />
          </Center>
        </Stack>
      </Center>
    </LoggedInUserWrapper>
  );
};

export default AssignmentEndScreen;

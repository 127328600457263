import React from 'react';
import { useDisclosure, Box } from '@chakra-ui/react';
import { ConfirmationModal, Button } from '../../shared';

const EndTest = (props) => {
  const { onEndTest, buttonText = 'End Test' } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button gap="4" px="6" onClick={onOpen} size={{ base: 'xs', md: 'md' }}>
        {buttonText}
      </Button>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        primaryButtonText={buttonText}
        secondaryButtonText="Cancel"
        modalText="Are you sure you want to end this assignment?"
        onAction={onEndTest}
      />
    </>
  );
};

export { EndTest };

import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { useAssignmentReview, useParams, useRedirectToHome } from '../../hooks';
import { useGetStudentAssignment } from '../../queries';
import LoggedInUserWrapper from '../core/LoggedInUserWrapper';
import ReviewQuestionAnswer from './ReviewQuestionAnswer';
import { FullPageLoader } from '../shared';
import { AssignmentReviewDrawer } from './AssignmentReviewDrawer';
import { Link } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';

const AssignmentReview = () => {
  const { studentAssignmentId } = useParams();

  const { isFetching, data: assignmentData } = useGetStudentAssignment(studentAssignmentId);
  const {
    name,
    drawerData,
    isFirstQuestion,
    isLastQuestion,
    currentQuestion,
    isCorrectlyAnswered,
    currentQuestionIndex,
    currentQuestionResponse,
    onNext,
    onPrevious
  } = useAssignmentReview({
    assignmentData
  });
  const { redirectToHome } = useRedirectToHome();

  if (isFetching) {
    <FullPageLoader />;
  }

  if (!assignmentData && !isFetching) {
    redirectToHome();
    return (
      <Center w="100%" h="100%">
        <Text>Invalid Assignment ID</Text>
      </Center>
    );
  }

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: (
          <Link to={'/'}>
            <AiOutlineHome size={25} />
          </Link>
        ),
        pageTitle: <Box textAlign={'center'}>{name}</Box>
      }}
    >
      <>
        <ReviewQuestionAnswer
          isFirstQuestion={isFirstQuestion}
          isLastQuestion={isLastQuestion}
          question={currentQuestion}
          questionIndex={currentQuestionIndex}
          isCorrectlyAnswered={isCorrectlyAnswered}
          questionResponse={currentQuestionResponse}
          onPrevious={onPrevious}
          isLoading={isFetching}
          onNext={onNext}
        />
        <AssignmentReviewDrawer answerStatuses={drawerData} />
      </>
    </LoggedInUserWrapper>
  );
};

export default AssignmentReview;

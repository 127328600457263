import React from 'react';
import { useAuth } from '../../hooks';
import { getLoginUrl } from '../../utils/urlHelpers';
import { FullPageLoader } from '../shared';
import { useNavigate } from 'react-router-dom';

export const withAuth = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { user, loading, error } = useAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
      if (!loading && !user) {
        navigate(getLoginUrl(window?.location?.pathname + window?.location?.search));
      }
    }, [user, loading, navigate]);

    if (loading || !user) {
      return <FullPageLoader />;
    }

    if (error) {
      navigate('/');
      console.error('Error while checking auth state: ', error);
      return <FullPageLoader />;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

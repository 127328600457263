import { useCallback, useMemo } from 'react';
import {
  useParams as useURLParams,
  useSearchParams,
  useNavigate,
  useLocation
} from 'react-router-dom';

export const useParams = () => {
  const params = useURLParams();
  const [searchParams] = useSearchParams();
  const query = useMemo(() => {
    const queryParams = {};
    for (const [key, value] of searchParams.entries()) {
      queryParams[key] = value;
    }

    return {
      ...params,
      ...queryParams
    };
  }, [params, searchParams]);

  return query;
};

export const useAddQueryParams = () => {
  const [, setSearchParams] = useSearchParams();
  const addQueryParam = useCallback(
    (params) => {
      setSearchParams((existingParams) => {
        const searchParams = {};
        for (const [key, value] of existingParams.entries()) {
          searchParams[key] = value;
        }

        for (const [key, value] of Object.entries(params)) {
          searchParams[key] = value;
        }

        return searchParams;
      });
    },
    [setSearchParams]
  );
  return addQueryParam;
};

export const useRemoveQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const removeQueryParams = useCallback(
    (paramsToRemove) => {
      const searchParams = new URLSearchParams(location.search);
      // Remove specified query parameters
      paramsToRemove.forEach((param) => {
        searchParams.delete(param);
      });

      // Update the URL without reloading the page
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString()
        },
        { replace: true }
      );
    },
    [navigate, location]
  );

  return removeQueryParams;
};

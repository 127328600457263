import React from 'react';
import { Image } from '@chakra-ui/react';
import parse from 'html-react-parser';
// import 'suneditor/dist/css/suneditor.min.css';
// import 'katex/dist/katex.min.css';

const HTMLRenderer = (props) => {
  const {
    element: { html, type }
  } = props;
  if (!html) {
    return null;
  }
  if (type === 'image') {
    return <Image src={html} alt="image" />;
  }
  if (type === 'table') {
    return <div className="sun-editor-editable">{parse(html)}</div>;
  }
  return <span>{parse(html)}</span>;
};

export { HTMLRenderer };

import React from 'react';
import { HStack, Box, Avatar, VStack } from '@chakra-ui/react';

import MarkdownWithKatex from './MarkdownWithKatex';
import { senitizeMessage } from '../../../utils';
import { useIsMobileDevice } from '../../../hooks';

function SingleChatWrapper({ isMobile, isSentByUser, children }) {
  if (isMobile) {
    return (
      <VStack align="baseline" flexDirection={'column'}>
        {children}
      </VStack>
    );
  }

  return <HStack flexDirection={isSentByUser ? 'row-reverse' : 'row'}>{children}</HStack>;
}

export default function Message({
  id,
  message,
  photoURL = '/cw-avatar.png',
  displayName = '',
  isSentByUser = false
}) {
  const isMobileDevice = useIsMobileDevice();

  return (
    <Box
      ml={isSentByUser ? 'auto' : '0'}
      position="relative"
      textAlign={isSentByUser ? 'right' : 'left'}
      my={3}
      id={id}
    >
      <SingleChatWrapper isSentByUser={isSentByUser} isMobile={isMobileDevice}>
        <Box
          mx={2}
          right={isMobileDevice && isSentByUser ? 0 : 'auto'}
          rounded
          position={isMobileDevice ? 'absolute' : 'relative'}
          bottom={isMobileDevice ? '-20px' : 'auto'}
        >
          <Avatar src={photoURL} name={displayName} size={isMobileDevice ? 'xs' : 'sm'} />
        </Box>
        <Box
          wordBreak="break-word"
          color={isSentByUser ? '#fff' : '#000'}
          bg={isSentByUser ? '#3369ff' : '#eee'}
          w="fit-content"
          p={5}
          margin={2}
          className={!isSentByUser ? 'htmlMarkdown' : ''}
          borderRadius={isSentByUser ? '30px 0px 30px 30px' : '30px 30px 30px 0px'}
        >
          <MarkdownWithKatex>{senitizeMessage(message)}</MarkdownWithKatex>
        </Box>
      </SingleChatWrapper>
    </Box>
  );
}

import { useQuery, useMutation } from '@tanstack/react-query';

import {
  createStudentAssignment,
  endStudentAssignment,
  getPracticeQuestionTopics,
  getStudentAssignment,
  getStudentAssignmentResult,
  getAllBadges,
  startStudentAssignment,
  updateBadges,
  updateResponseForStudentAssignment,
  updateTimeRemaining,
  deleteUserBadge
} from '../api';
import { useToast } from '@chakra-ui/react';
import { useAuth, useParams } from '../hooks';
import { getAssignmentQuestionUrl } from '../utils/urlHelpers';
import { useNavigate } from 'react-router-dom';

export const useGetStudentAssignment = (studentAssignmentId) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ['studentAssignment'],
    queryFn: () => getStudentAssignment({ studentAssignmentId, token }),
    enabled: !!studentAssignmentId
  });
};

export const useCreateStudentAssignment = (assignmentId) => {
  const toast = useToast();
  const { token } = useAuth();

  return useMutation({
    mutationFn: () => createStudentAssignment({ assignmentId, token }),
    onError: (e) => {
      const message = e?.response?.data?.message || "Couldn't load your assignment";
      toast({
        title: 'Assignment load error',
        description: message,
        status: 'error',
        isClosable: true
      });
    }
  });
};

export const useStartStudentAssignment = (studentAssignmentId) => {
  const { token } = useAuth();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () => startStudentAssignment({ studentAssignmentId, token }),
    onSuccess: ({ data }) => {
      if (data?.studentResponse) {
        navigate(
          getAssignmentQuestionUrl({
            studentAssignmentId,
            questionId: data.studentResponse[0].questionId
          })
        );
      }
    },
    onError: (e) => {
      const message = e?.response?.data?.message || "Couldn't load your assignment";
      toast({
        title: 'Assignment load error',
        description: message,
        status: 'error',
        isClosable: true
      });
    }
  });
};

export const useUpdateStudentAssignment = (assignmentId) => {
  const { token } = useAuth();

  return useMutation({
    mutationFn: (data) => updateResponseForStudentAssignment({ assignmentId, token, data })
  });
};

export const useBookmarkQuestion = (assignmentId) => {
  const { token } = useAuth();

  return useMutation({
    mutationFn: (data) => updateResponseForStudentAssignment({ assignmentId, token, data })
  });
};

export const useEndStudentAssignment = (studentAssignmentId) => {
  const { token } = useAuth();
  return useMutation({
    mutationFn: () => endStudentAssignment({ studentAssignmentId, token })
  });
};

export const useStudentAssignmentResult = (studentAssignmentId) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ['student-assigment-result', { studentAssignmentId }],
    queryFn: () => getStudentAssignmentResult({ studentAssignmentId, token })
  });
};

export const useUpdateTimeRemaining = (studentAssignmentId) => {
  const { token } = useAuth();
  return useMutation({
    mutationFn: (timeRemaining) =>
      updateTimeRemaining({ studentAssignmentId, token, data: { timeRemaining } })
  });
};

export const useUpdateBadges = () => {
  const { badges } = useParams();
  const { token } = useAuth();
  return useMutation({
    mutationFn: () => updateBadges({ token, data: { badges } })
  });
};

export const useUpdateBadgesFromModal = () => {
  const { token } = useAuth();
  return useMutation({
    mutationFn: (badges) => updateBadges({ token, data: { badges } })
  });
};

export const useDeleteBadgeFromModal = () => {
  const { token } = useAuth();  
  return useMutation({
    mutationFn: (badge) => deleteUserBadge({ token, data: { badge } })
  });
};

export const useGetAllBadges = () => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ['all-badges'],
    queryFn: () => getAllBadges({ token })
  });
};

export const useGetPracticeQuestionTopics = () => {
  const { token } = useAuth();
  const { badges } = useParams();
  return useQuery({
    queryKey: ['practice-question-topics'],
    queryFn: () => getPracticeQuestionTopics({ token, badges })
  });
};

export const SOCKET_STATUS = {
  NOT_CONENCTED: 'NOT_CONNECTED',
  IDLE: 'IDLE',
  INITIATED: 'INITIATED',
  CONNECTED: 'CONNECTED',
  READY: 'READY',
  CLOSED: 'CLOSED'
};

export const SOCKET_MESSAGE_TYPES = {
  STUDENT_CHAT: 'STUDENT_CHAT'
};

export const STUDENT_CHAT_MESSAGE_TYPES = {
  INIT: 'INIT',
  MESSAGE: 'MESSAGE',
  END: 'END',
  ERROR: 'ERROR',
  RESUME: 'RESUME',
  REFRESH: 'REFRESH'
};

export const STUDENT_CHAT_TYPES = {
  PRACTISE_QUESTION: 'PRACTISE_QUESTION',
  ASK_ME_QUESTIONS: 'ASK_ME_QUESTIONS',
  LEARN_A_TOPIC: 'LEARN_A_TOPIC'
};

export const STUDENT_CHAT_ERROR_TYPE = {
  CREDIT_LIMIT_EXHAUSTED: 'CREDIT_LIMIT_EXHAUSTED',
  CHAT_NOT_INITIALISED: 'CHAT_NOT_INITIALISED'
};

export const MAX_CONNECTION_TRIES = 10;
export const CONNECTION_WAIT = 1_000;

import React from 'react';
import { Flex, Box, Text, Stack, Divider, RadioGroup } from '@chakra-ui/react';
import { QuestionResultTag } from './QuestionResultTag';
import { ElementsRenderer } from '../shared';
import { AssignmentReviewOption } from './AssignmentReviewOption';

const MCQReview = ({ question, response, isCorrectlyAnswered }) => {
  const { options, elements, solution } = question;
  const { answer, status } = response;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text color={'#686868'}>Multiple Choice Question</Text>
        <QuestionResultTag
          isCorrectlyAnswered={isCorrectlyAnswered}
          status={status}
          cssProps={{
            display: { base: 'none', md: 'inline-flex' }
          }}
        />
      </Flex>
      {/* Question Content */}
      <Box my="2" w="100%">
        <Box fontSize="lg">
          <ElementsRenderer elements={elements} />
        </Box>
      </Box>
      <QuestionResultTag
        isCorrectlyAnswered={isCorrectlyAnswered}
        status={status}
        cssProps={{
          display: { base: 'block', md: 'none' },
          mb: 4
        }}
      />
      {/* Options */}
      <Stack w="100%">
        <Divider />
        <RadioGroup defaultValue={answer[0]}>
          <Stack direction="column">
            {options?.map((option) => {
              return (
                <AssignmentReviewOption
                  key={option.id}
                  {...option}
                  isCorrectlyAnswered={isCorrectlyAnswered}
                />
              );
            })}
          </Stack>
        </RadioGroup>
      </Stack>
      {/* Solution */}
      <Stack mt={3}>
        <Text>Detail Solution</Text>
        <Box color="grey">
          <ElementsRenderer elements={solution?.elements} />
        </Box>
      </Stack>
    </Box>
  );
};

export default MCQReview;

import React from 'react';
import { Button as ChakraUiButton } from '@chakra-ui/react';

const Button = (props) => {
  const { children, buttonType = 'primary', ...restProps } = props;

  let background = '#5D38DB';
  let borderColor = '#5D38DB';
  let color = '#FFFFFF';

  if (buttonType === 'secondary') {
    background = '#fff';
    borderColor = '#686868';
    color = '#686868';
  }

  return (
    <ChakraUiButton
      _hover={{
        opacity: 0.8
      }}
      fontSize={{ base: 'xs', md: 'md' }}
      rounded={0}
      background={background}
      border="1px solid"
      borderColor={borderColor}
      color={color}
      {...restProps}
    >
      {children}
    </ChakraUiButton>
  );
};

export { Button };

export const getAssignmentQuestionUrl = ({ studentAssignmentId, questionId }) => {
  return `/assignment/${studentAssignmentId}/${questionId}`;
};

export const getAssignmentStartUrl = ({ assignmentId }) => {
  return `/start/${assignmentId}`;
};

export const getAssignmentQuestionURL = ({ studentAssignmentId, questionId }) => {
  return `/assignment/${studentAssignmentId}/${questionId}`;
};

export const getAssignmentEndUrl = ({ studentAssignmentId }) => {
  return `/assignment/${studentAssignmentId}/end`;
};

export const getHomePageUrl = () => {
  return '/';
};

export const getReviewQuestionUrl = ({ studentAssignmentId, questionId }) => {
  return `/assignment/${studentAssignmentId}/review/${questionId}`;
};

export const getLoginUrl = (redirectUrl) => {
  const loginUrl = '/login';
  if (redirectUrl) {
    return `${loginUrl}?redirectTo=${redirectUrl}`;
  }
  return loginUrl;
};

export const getPaymentSuccessUrl = (transactionId) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('upgradeStatus', 'success');
  currentUrl.searchParams.set('transactionId', transactionId);
  return `${currentUrl.pathname}${currentUrl.search}`;
};
import React from 'react';
import {
  Flex,
  Box,
  Center,
  Circle,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react';

import { Summary } from './Summary';
import { Section } from './Section';

const AssignmentDrawer = (props) => {
  const { studentResponse, isReview } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  if (!studentResponse) {
    return null;
  }
  return (
    <>
      <Flex
        flexDirection="column"
        w="28px"
        h="100%"
        backgroundColor="white"
        position="absolute"
        right="0"
        top="0"
        justifyContent="center"
        boxShadow={{ base: '', md: '-6px 0 6px #d9d9d9' }}
        alignItems="center"
      >
        <Circle
          w="40px"
          h="40px"
          border="1px solid #C9CDDA"
          bg="white"
          ref={btnRef}
          onClick={onOpen}
          cursor="pointer"
          ml={{ base: 0, md: -8 }}
          mr={{ base: -8, md: 0 }}
          boxShadow="0 0 6px #d9d9d9"
        >
          <Center>{'<<'}</Center>
        </Circle>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        id="drawer-main"
        isFullHeight={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {/* <DrawerHeader>Create your account</DrawerHeader> */}

          <DrawerBody>
            <Box ml={5}>
              <Summary studentResponse={studentResponse} />
              <Section
                id={1}
                questions={studentResponse}
                closeDrawer={onClose}
                isReview={isReview}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export { AssignmentDrawer };

/* eslint-disable react/display-name */
import { Avatar, Center, Circle } from '@chakra-ui/react';
import React from 'react';

const ProfilePhoto = React.forwardRef((props, ref) => {
  const { name, onClick } = props;
  const [fName, lName] = name?.split(' ') ?? [];
  let profileText = 'X';
  if (fName && lName) {
    profileText = `${fName?.[0]}${lName?.[0]}`.toUpperCase();
  } else if (fName) {
    profileText = fName[0].toUpperCase();
  }

  return (
    <Circle
      w={{ base: '24px', md: '36px' }}
      h={{ base: '24px', md: '36px' }}
      fontSize={{ base: '12px', md: '16px' }}
      cursor="pointer"
      ref={ref}
      name={name}
      onClick={onClick}
      border="1px solid grey"
    >
      <Center>{profileText}</Center>
    </Circle>
  );
});

export default ProfilePhoto;

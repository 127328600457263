import { useMemo } from 'react';
import { useParams } from './useRouteParams';
import {
  getAnswerStatus,
  getAssignmentDetails,
  getIsCorrectlyAnswered,
  getQuestionFromQuestionId,
  getReviewQuestionUrl
} from '../utils';
import { REVIEW_STATUS, QUESTION_RESPONSE_STATUS } from '../constants';
import { useNavigate } from 'react-router-dom';

const useAssignmentReview = ({ assignmentData }) => {
  const { questionId, studentAssignmentId } = useParams();
  const navigate = useNavigate();
  const {
    name,
    nextQuestionId,
    isLastQuestion,
    currentQuestion,
    isFirstQuestion,
    previousQuestionId,
    currentQuestionIndex,
    currentQuestionResponse
  } = useMemo(
    () =>
      getAssignmentDetails({
        assignmentData,
        questionId
      }),
    [assignmentData, questionId]
  );

  if (!assignmentData) {
    return {};
  }

  const drawerData = assignmentData.studentResponse.map((questionResponse) => {
    const { questionId, status } = questionResponse;
    const data = {
      questionId,
      status: REVIEW_STATUS.SKIPPED
    };
    if (status === QUESTION_RESPONSE_STATUS.ANSWERED) {
      const question = getQuestionFromQuestionId(assignmentData.assignment, questionId);
      data.status = getAnswerStatus(question, questionResponse);
    }
    return data;
  });

  currentQuestion.options = currentQuestion.options.filter(
    (option) => option.id === currentQuestionResponse.answer?.[0] || option.isCorrect
  );

  const onNext = () => {
    navigate(getReviewQuestionUrl({ studentAssignmentId, questionId: nextQuestionId }));
  };

  const onPrevious = () => {
    navigate(getReviewQuestionUrl({ studentAssignmentId, questionId: previousQuestionId }));
  };

  const isCorrectlyAnswered = getIsCorrectlyAnswered(currentQuestion, currentQuestionResponse);
  const answerStatus = getAnswerStatus(currentQuestion, currentQuestionResponse);

  return {
    currentQuestion,
    isCorrectlyAnswered,
    answerStatus,
    currentQuestionIndex,
    currentQuestionResponse,
    onNext,
    onPrevious,
    name,
    drawerData,
    isFirstQuestion,
    isLastQuestion
  };
};

export { useAssignmentReview };

import { extendTheme } from '@chakra-ui/react'


const breakpoints = {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
  }

const theme = extendTheme({
  fonts: {
    body: `'Open Sans', sans-serif`
  },
  fontSizes: {
    lg: '18px'
  }
  // ...breakpoints
});

export default theme
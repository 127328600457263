import React from 'react';

import { withAuth } from '../auth/withAuth';
import ChatTopicSelection from '../shared/Chat/ChatTopicSelection';

function AskMeQuestionsTopicSelection() {
  return <ChatTopicSelection title="Ask me Questions" navigationPath="ask-me-questions" />;
}
const AskMeQuestionsTopicSelectionWithAuth = withAuth(AskMeQuestionsTopicSelection);
export default AskMeQuestionsTopicSelectionWithAuth;

import React from 'react';
import ChatMain from '../shared/Chat/ChatMain';
import { STUDENT_CHAT_TYPES } from '../../constants/socket-constants';
import { withAuth } from '../auth';

function LearnNewTopicChat() {
  return <ChatMain title="Learn a new topic" chatType={STUDENT_CHAT_TYPES.LEARN_A_TOPIC} />;
}

const LearnNewTopicChatWithAuth = withAuth(LearnNewTopicChat);

export default LearnNewTopicChatWithAuth;

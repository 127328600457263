import { useCallback, useEffect, useState } from 'react';
import { useWebSocket } from './useWebSocket';
import { SOCKET_MESSAGE_TYPES, STUDENT_CHAT_MESSAGE_TYPES } from '../constants/socket-constants';
import { useAuth } from './useAuth';

const useStudentChat = ({ onMessageRef, onReconnectionRef }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { token } = useAuth();

  const { status, sendMessage } = useWebSocket({
    onMessageRef,
    onReconnectionRef
  });

  const sendStudentChatMessage = useCallback(
    (payload) => {
      sendMessage({
        type: SOCKET_MESSAGE_TYPES.STUDENT_CHAT,
        payload
      });
    },
    [sendMessage]
  );

  useEffect(() => {
    sendStudentChatMessage({
      type: STUDENT_CHAT_MESSAGE_TYPES.REFRESH,
      data: { token }
    });
  }, [token, sendStudentChatMessage]);

  const initialize = useCallback(
    ({ topicId, chatType }) => {
      if (!isInitialized) {
        sendStudentChatMessage({
          type: STUDENT_CHAT_MESSAGE_TYPES.INIT,
          data: {
            topicId,
            chatType
          }
        });
        setIsInitialized(true);
      }
    },
    [sendStudentChatMessage, isInitialized, setIsInitialized]
  );

  const sendStudentMessage = useCallback(
    (messageStr) => {
      sendStudentChatMessage({
        type: STUDENT_CHAT_MESSAGE_TYPES.MESSAGE,
        data: {
          message: messageStr
        }
      });
    },
    [sendStudentChatMessage]
  );

  const endChat = useCallback(() => {
    sendStudentChatMessage({
      type: STUDENT_CHAT_MESSAGE_TYPES.END
    });
  }, [sendStudentChatMessage]);

  const resumeChat = useCallback(
    ({ shouldFetchMessages, threadId }) => {
      sendStudentChatMessage({
        type: STUDENT_CHAT_MESSAGE_TYPES.RESUME,
        data: { shouldFetchMessages, threadId }
      });
    },
    [sendStudentChatMessage]
  );

  return {
    isInitialized,
    initialize,
    resumeChat,
    sendStudentMessage,
    endChat,
    status
  };
};

export { useStudentChat };

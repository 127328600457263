import React from 'react';
import { Box, Text, SimpleGrid, Circle, Image, Flex } from '@chakra-ui/react';
import { QUESTION_RESPONSE_STATUS, questionStatusMap } from '../../../constants';
import { useParams } from '../../../hooks';
import { getAssignmentQuestionURL, getReviewQuestionUrl } from '../../../utils/urlHelpers';
import { useNavigate } from 'react-router-dom';

const Section = (props) => {
  const { id, questions, closeDrawer, isReview } = props;
  const { studentAssignmentId, questionId } = useParams();
  const navigate = useNavigate();

  const handleQuestionClick = (clickedQuestionId) => {
    if (questionId !== clickedQuestionId) {
      const redirectUrlFn = isReview ? getReviewQuestionUrl : getAssignmentQuestionURL;
      navigate(redirectUrlFn({ studentAssignmentId, questionId: clickedQuestionId }));
      closeDrawer();
    }
  };

  return (
    <Box mt={5}>
      <Flex borderBottom="1px solid #0000001F" align="center">
        {/* <Image src="/assets/images/drawer-section.svg" mr={2} />
        <Text fontWeight={400} color="#686868">
          {`Section ${id}`}
        </Text> */}
      </Flex>
      <SimpleGrid columns={4} spacing={15} mt="20px">
        {questions.map(({ questionId: id, status, isBookmarked }, index) => (
          <Circle
            bg={
              isBookmarked
                ? questionStatusMap[QUESTION_RESPONSE_STATUS.BOOKMARKED].color
                : questionStatusMap[status].color
            }
            w="40px"
            h="40px"
            key={id}
            cursor="pointer"
            border={id === questionId ? '2px solid' : ''}
            onClick={() => handleQuestionClick(id)}
          >
            <Text>{index + 1}</Text>
          </Circle>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export { Section };

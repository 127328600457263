import React, { useEffect } from 'react';
import { Center, Stack } from '@chakra-ui/react';

import { getAssignmentQuestionUrl, getAssignmentEndUrl, msToTime } from '../../utils';
import {
  useCreateStudentAssignment,
  useGetAssignment,
  useStartStudentAssignment
} from '../../queries';
import { STUDENTASSIGNMENT_STATUS } from '../../constants';
import LoggedInUserWrapper from '../core/LoggedInUserWrapper';
import { Button, FullPageLoader, ElementsRenderer } from '../shared';
import { useStartScreen } from '../../hooks/useStartScreen';
import { useNavigate } from 'react-router-dom';

const AssignmentStartScreen = (props) => {
  const { assignmentId } = props;

  const { data: assignmentData, isLoading } = useGetAssignment(assignmentId);
  const [studentAssignment, setStudentAssignment] = React.useState(null);

  const {
    mutate: createStudentAssignment,
    isCreateLoading,
    data: createData
  } = useCreateStudentAssignment(assignmentId);
  const { bannerMessage, allowStart, isAssignmentCompleted, studentAssignmentId } = useStartScreen({
    assignmentData,
    createData: createData?.data
  });

  const { mutate: startStudentAssignment, isStartLoading } = useStartStudentAssignment(
    studentAssignment?._id
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isCreateLoading && !createData) {
      createStudentAssignment(null, {
        onSuccess: ({ data }) => {
          setStudentAssignment(data);
        }
      });
    }
  }, [isCreateLoading, createData, createStudentAssignment]);

  useEffect(() => {
    if (isAssignmentCompleted) {
      navigate(getAssignmentEndUrl({ studentAssignmentId }));
    }
  }, [isAssignmentCompleted, navigate, studentAssignmentId]);

  if (isLoading || !assignmentData || isAssignmentCompleted) {
    return <FullPageLoader />;
  }

  const {
    duration,
    name,
    welcomeScreen: { elements },
    startButtonText,
    questions
  } = assignmentData;

  const startText =
    isCreateLoading || isStartLoading || !studentAssignment
      ? 'Loading'
      : studentAssignment?.status === STUDENTASSIGNMENT_STATUS.YET_TO_START
      ? startButtonText
      : 'Resume my assignment';

  const handleStart = () => {
    if (studentAssignment?.status === STUDENTASSIGNMENT_STATUS.YET_TO_START) {
      startStudentAssignment();
    } else {
      const questionId = studentAssignment?.studentResponse[0]?.questionId;
      navigate(
        getAssignmentQuestionUrl({
          studentAssignmentId: studentAssignment._id,
          questionId: questionId
        })
      );
    }
  };

  return (
    <LoggedInUserWrapper
      bannerProps={{ message: bannerMessage }}
      headerProps={{
        title: name,
        time: duration ? msToTime(duration) : null,
        totalQuestions: questions ? questions.length : 0
      }}
    >
      <Center>
        <Stack w="90%" mt={3}>
          <ElementsRenderer elements={elements} />
          <Center>
            <Button
              mt={4}
              isDisabled={isCreateLoading || isStartLoading || !allowStart}
              isLoading={isCreateLoading || isStartLoading}
              loadingText="Getting the test ready"
              onClick={handleStart}
              d="inline-block"
            >
              {startText}
            </Button>
          </Center>
        </Stack>
      </Center>
    </LoggedInUserWrapper>
  );
};

export default AssignmentStartScreen;

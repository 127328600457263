import { useCallback, useEffect, useReducer } from 'react';

// type Actions =
//   | { type: 'START' }
//   | { type: 'RESET'; payload: number }
//   | { type: 'PAUSE' }
//   | { type: 'RUNNING' }
//   | { type: 'TICK'; payload: number }

// type State = {
//   canStart: boolean
//   countdown: number
//   isRunning: boolean
// }

function reducer(state, action) {
  switch (action.type) {
    case 'START':
      return {
        ...state,
        countdown: action.payload || state.countdown,
        canStart: (action.payload || state.countdown) !== 0
      };
    case 'RESET':
      return {
        ...state,
        countdown: action.payload,
        canStart: false,
        isRunning: false
      };
    case 'PAUSE':
      return {
        ...state,
        canStart: false,
        isRunning: false
      };
    case 'RUNNING':
      return {
        ...state,
        isRunning: true
      };
    case 'TICK':
      return {
        ...state,
        countdown: state.countdown - action.payload
      };
    /* istanbul ignore next */
    default:
      return state;
  }
}

/**
 * Create a configurable countdown timer.
 */
export function useCountdownTimer({
  timer,
  interval = 1000,
  autostart = false,
  expireImmediate = false,
  resetOnExpire = false,
  onExpire,
  onReset,
  onTick = () => {}
}) {
  const [state, dispatch] = useReducer(reducer, {
    canStart: autostart,
    countdown: timer,
    isRunning: false
  });

  function start(countdown) {
    dispatch({ type: 'START', payload: countdown });
  }

  function pause() {
    dispatch({ type: 'PAUSE' });
  }

  function initStopped(time) {
    dispatch({ type: 'RESET', payload: time });
  }

  const reset = useCallback(() => {
    initStopped(timer);
    if (onReset && typeof onReset === 'function') {
      onReset();
    }
  }, [timer, onReset]);

  const expire = useCallback(() => {
    initStopped(resetOnExpire ? timer : 0);
    if (onExpire && typeof onExpire === 'function') {
      onExpire();
    }
  }, [timer, onExpire, resetOnExpire]);

  useEffect(() => {
    function tick() {
      if (
        state.countdown / 1000 <= 0 ||
        (expireImmediate && (state.countdown - interval) / 1000 <= 0)
      ) {
        expire();
      } else {
        dispatch({ type: 'TICK', payload: interval });
        onTick(state.countdown);
      }
    }

    let id;
    if (state.canStart) {
      id = setInterval(tick, interval);
      if (!state.isRunning) {
        dispatch({ type: 'RUNNING' });
      }
    }
    return () => clearInterval(id);
  }, [expire, expireImmediate, interval, state.canStart, state.countdown, state.isRunning, onTick]);

  return {
    countdown: state.countdown,
    isRunning: state.isRunning,
    start,
    reset,
    pause
  };
}

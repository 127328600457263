import React from 'react';
import { Box, Circle, Flex, Text, SimpleGrid } from '@chakra-ui/react';
import { QUESTION_RESPONSE_STATUS, questionStatusMap } from '../../../constants';

const Summary = (props) => {
  const { studentResponse } = props;

  const summaryStatusMap = structuredClone(questionStatusMap);
  Object.keys(summaryStatusMap).map((status) => {
    summaryStatusMap[status].count = 0;
  });

  studentResponse.forEach((response) => {
    const { status, isBookmarked } = response;
    if (status !== QUESTION_RESPONSE_STATUS.VISITED) {
      summaryStatusMap[status].count++;
      if (isBookmarked) {
        summaryStatusMap[QUESTION_RESPONSE_STATUS.BOOKMARKED].count++;
      }
    }
  });

  const statusArray = [
    summaryStatusMap[QUESTION_RESPONSE_STATUS.ANSWERED],
    summaryStatusMap[QUESTION_RESPONSE_STATUS.SKIPPED],
    summaryStatusMap[QUESTION_RESPONSE_STATUS.BOOKMARKED],
    summaryStatusMap[QUESTION_RESPONSE_STATUS.NOT_VISITED]
  ];

  return (
    <SimpleGrid columns={2} columnGap={6} rowGap={1}>
      {statusArray.map(({ color, uiText, count }, index) => (
        <Box mt={4} key={`${uiText}-${index}`}>
          <Flex justify="center" mb={-1}>
            <Circle h="33px" w="33px" backgroundColor={color}>
              <Text fontWeight={700} fontSize="xl">
                {count}
              </Text>
            </Circle>
          </Flex>
          <Flex
            backgroundColor="#d9d9d9"
            h="28px"
            borderRadius="16px"
            alignItems="center"
            justify="center"
          >
            <Text fontWeight={700} fontSize="xs">
              {uiText}
            </Text>
          </Flex>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export { Summary };

import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Home from './pages/index';
import ClasswiseLogin from '../login';
import StartAssignment from './pages/start-assignment';
import PracticeQuestionTopicSelection from '../PracticeQuestions/PracticeQuestionTopicSelection';
import PracticeQestionChat from '../PracticeQuestions/PracticeQuestionChat';
import LearnNewTopicSelection from '../LearnNewTopic/LearnNewTopicSelection';
import LearnNewTopicQuestionChat from '../LearnNewTopic/LearnNewTopicQuestionChat';
import Join from './pages/join';
import AskmeQuestionTopicSelection from '../AskmeQuestions/AskmeQuestionTopicSelection';
import AskmeQuestionChat from '../AskmeQuestions/AskmeQuestionChat';
import Assignment from './pages/assignment/index';
import StudentAssignment from './pages/assignment/student-assignment';
import Assignments from '../assignments';
import AssignmentEnd from './pages/assignment-end';
import AssignmentQuestionReview from './pages/assignment-question-review';
import AssignmentReview from './pages/assignment-review';
import DeleteUser from './pages/DeleteUser';
import { ASK_ME_QUESTIONS_URL, LEARN_A_TOPIC_URL, PRACTICE_QUESTIONS_URL } from '../../constants';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/login',
    element: <ClasswiseLogin />
  },
  {
    path: '/start/:assignmentId',
    element: <StartAssignment />
  },
  {
    path: PRACTICE_QUESTIONS_URL,
    element: <PracticeQuestionTopicSelection />
  },
  {
    path: `/${PRACTICE_QUESTIONS_URL}/:chatTopicId`,
    element: <PracticeQestionChat />
  },
  {
    path: LEARN_A_TOPIC_URL,
    element: <LearnNewTopicSelection />
  },
  {
    path: `${LEARN_A_TOPIC_URL}/:chatTopicId`,
    element: <LearnNewTopicQuestionChat />
  },
  {
    path: '/join',
    element: <Join />
  },
  {
    path: ASK_ME_QUESTIONS_URL,
    element: <AskmeQuestionTopicSelection />
  },
  {
    path: `${ASK_ME_QUESTIONS_URL}/:chatTopicId`,
    element: <AskmeQuestionChat />
  },
  {
    path: '/assignment',
    element: <Assignment />
  },
  {
    path: '/assignment/:studentAssignmentId',
    element: <StudentAssignment />
  },
  {
    path: '/assignment/:studentAssignmentId/:questionId',
    element: <Assignments />
  },
  {
    path: '/assignment/:studentAssignmentId/end',
    element: <AssignmentEnd />
  },
  {
    path: '/assignment/:studentAssignmentId/review',
    element: <AssignmentReview />
  },
  {
    path: '/assignment/:studentAssignmentId/review/:questionId',
    element: <AssignmentQuestionReview />
  },
  {
    path: '/delete/user',
    element: <DeleteUser />
  }
]);

const AppRoutes = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

export default AppRoutes;

import React from 'react';
import { Flex, Heading, VStack, Stack, Grid, Text, Center, Spinner } from '@chakra-ui/react';
import PricingBox from './PricingBox';
import { usePricingPlans } from '../../../hooks';
import { themeColorHex } from '../../../constants';
import { useConsumption } from '../../../queries';

const UpgradePlan = ({ closePlans }) => {
  const { prices, isLoading } = usePricingPlans();
  const { data: consumptionData } = useConsumption();

  const title =
    consumptionData?.credits <= 0
      ? 'Your daily free credit limit has been exhausted'
      : 'Choose your path to Success';
  if (isLoading) {
    return (
      <Center w="100%" h="400px">
        <Spinner />
      </Center>
    );
  }
  return (
    <Flex direction="column" alignItems="center" justifyContent="center" w="full">
      <Stack
        spacing={0}
        marginY={5}
        justifyContent="flex-start"
        alignItems="center"
        maxWidth="1200px"
        w="full"
        paddingX={[5, 0]}
      >
        <VStack alignItems="center" w="full">
          <Heading color={themeColorHex} as="h2" size="lg">
            {title}
          </Heading>
          <Text textAlign="center" mt={4}>
            Unlock Full Access - Unlimited Practise, Doubt Solving and Personalised Training
          </Text>
        </VStack>
        {/* <Stack
          spacing={0}
          isInline
          border="1px solid"
          borderColor={themeColorHex}
          borderRadius="4px"
          justifyContent="center"
          alignItems="stretch"
          display="flex"
          width="fit-content"
          backgroundColor="white"
          mb={5}
        >
          <Box backgroundColor={themeColorHex} color="white" p=".3rem 1rem">
            Monthly
          </Box>
          <Box p=".3rem 1rem">Annually</Box>
        </Stack> */}
        <Grid
          w="full"
          gap={5}
          justifyContent="center"
          templateColumns={{
            base: 'inherit',
            md: 'repeat( auto-fit, 250px )'
          }}
        >
          {prices.map((price) => (
            <PricingBox key={price.name} closePlans={closePlans} {...price} />
          ))}
        </Grid>
      </Stack>
    </Flex>
  );
};

export default UpgradePlan;

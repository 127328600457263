import React from 'react';
import { Divider, Radio, Flex, Tag, Box } from '@chakra-ui/react';
import { ElementsRenderer } from '../shared';

const AssignmentReviewOption = (props) => {
  const { elements, id, isCorrect, isCorrectlyAnswered } = props;
  let tagText = 'Your Answer';
  let tagColor = 'green';

  if (!isCorrectlyAnswered) {
    if (isCorrect) {
      tagText = 'Correct Answer';
    } else {
      tagColor = 'red';
    }
  }

  return (
    <Box w="100%">
      <Flex
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        flexDirection={{ base: 'column', md: 'row' }}
        w="100%"
        pb={2}
        alignItems="center"
      >
        <Radio colorScheme="purple" py="2" value={id} w="100%">
          <ElementsRenderer elements={elements} />
        </Radio>
        <Tag
          colorScheme={tagColor}
          color="black"
          fontWeight="600"
          whiteSpace="nowrap"
          mr={3}
          h={{ base: '32px', md: '38px' }}
          w="200px"
          borderRadius="25px"
          justifyContent="center"
        >
          {tagText}
        </Tag>
      </Flex>
      <Divider />
    </Box>
  );
};

export { AssignmentReviewOption };

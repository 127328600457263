import { useToast } from '@chakra-ui/react';
import { QUESTION_RESPONSE_STATUS } from '../constants';
import { useParams } from './useRouteParams';
import {
  useBookmarkQuestion,
  useEndStudentAssignment,
  useUpdateStudentAssignment,
  useUpdateTimeRemaining
} from '../queries';
import { useCountdownTimer } from './useCountdownTimer';
import { getAssignmentDetails, msToTime, getAssignmentEndUrl } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

export const useStudentAssignmentActions = ({ assignmentData, refetchAssignment }) => {
  const { questionId, studentAssignmentId } = useParams();
  const navigate = useNavigate();

  const { mutate: updateStudentAssignment, isLoading: isUpdateLoading } =
    useUpdateStudentAssignment(studentAssignmentId);
  const { mutate: bookmarkQuestion, isLoading: isBookmarkLoading } =
    useBookmarkQuestion(studentAssignmentId);
  const { mutate: endStudentAssignment, isLoading: isEndAssignmentLoading } =
    useEndStudentAssignment(studentAssignmentId);

  const {
    currentQuestion,
    currentQuestionIndex,
    currentQuestionResponse,
    nextQuestionId,
    isLastQuestion
  } = useMemo(() => {
    try {
      return getAssignmentDetails({
        assignmentData,
        questionId
      });
    } catch (e) {
      return {};
    }
  }, [assignmentData, questionId]);

  if (!assignmentData) {
    return {
      assignment: null
    };
  }

  const { assignment } = assignmentData;
  const navigateToNextQuestion = () => {
    if (nextQuestionId) {
      navigate(`/assignment/${studentAssignmentId}/${nextQuestionId}`);
    } else {
      // Handle assignment end
      console.info('Assignment end!!');
    }
  };

  const navigateToEndScreen = () => {
    navigate(getAssignmentEndUrl({ studentAssignmentId }));
  };

  const onUpdateSuccess = () => {
    navigateToNextQuestion();
    refetchAssignment();
  };

  const onSkip = (questionResponse) => {
    questionResponse.status = QUESTION_RESPONSE_STATUS.SKIPPED;
    questionResponse.answer = [];
    updateStudentAssignment(questionResponse, { onSuccess: onUpdateSuccess });
  };

  const onSaveAndNext = (data) => {
    const { questionResponse, answer, answers, shouldEndTest } = data;
    if (answer) questionResponse.answer = [answer]; // Setting for MCQ type
    if (answers) questionResponse.answers = answers; // Setting for FIB type
    questionResponse.status = QUESTION_RESPONSE_STATUS.ANSWERED;
    updateStudentAssignment(questionResponse, {
      onSuccess: () => {
        if (shouldEndTest) {
          onEndTest();
        } else {
          onUpdateSuccess();
        }
      }
    });
  };

  const bookmarkAction = (questionResponse) => {
    questionResponse.isBookmarked = !questionResponse.isBookmarked;
    bookmarkQuestion(questionResponse, { onSuccess: refetchAssignment });
  };

  const visitQuestion = (questionResponse) => {
    questionResponse.status = QUESTION_RESPONSE_STATUS.VISITED;
    updateStudentAssignment(questionResponse, { onSuccess: refetchAssignment });
  };

  const onEndTest = () => {
    endStudentAssignment(studentAssignmentId, { onSuccess: navigateToEndScreen });
  };

  return {
    assignment,
    isLastQuestion,
    currentQuestion,
    isBookmarkLoading,
    currentQuestionIndex,
    isEndAssignmentLoading,
    currentQuestionResponse,
    isLoading: isUpdateLoading,
    onSkip,
    onEndTest,
    visitQuestion,
    onSaveAndNext,
    bookmarkAction
  };
};

export const useStudentAssignmentTimer = ({
  timeRemaining,
  studentAssignmentId,
  isLoading,
  onEndTest
}) => {
  const toast = useToast();
  const { mutate: updateTimeRemaining } = useUpdateTimeRemaining(studentAssignmentId);
  const onTick = (remainingTime) => {
    if ((remainingTime / 1000) % 2 === 0) {
      // Updating time to server every 2 seconds
      updateTimeRemaining(remainingTime);
    }
  };

  const onTimerEnd = () => {
    toast({
      title: 'Time over!',
      status: 'warning',
      isClosable: true
    });
    onEndTest();
  };

  const { countdown, start, pause } = useCountdownTimer({
    timer: timeRemaining || 0,
    autostart: false,
    onTick,
    onExpire: onTimerEnd
  });

  useEffect(() => {
    if (!isLoading) {
      start(timeRemaining);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, isLoading]);

  return { countdown: msToTime(countdown), pause, start };
};

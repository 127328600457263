import React from 'react';
import { useParams } from 'react-router-dom';
import AssignmentStartScreen from '../../AssignmentStartScreen';
import { withAuth } from '../../auth/withAuth';

const AssignmentStartPage = () => {
  const { assignmentId } = useParams();
  return <AssignmentStartScreen assignmentId={assignmentId} />;
};

const AssignmentStartPageWithAuth = withAuth(AssignmentStartPage);

export default AssignmentStartPageWithAuth;

import { useQuery } from '@tanstack/react-query';
import { getAssignment } from '../api';
import { useAuth } from '../hooks';

export const useGetAssignment = (assignmentId) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ['get-assignment'],
    queryFn: () => getAssignment({ assignmentId, token })
  });
};
  

import React from 'react';
import { Box, Image, Spinner, Center, Flex } from '@chakra-ui/react';

const BookmarkButton = (props) => {
  const { bookmarkAction, isBookmarked, isLoading } = props;

  const text = isBookmarked ? 'Bookmarked' : 'Bookmark';
  const imageSrc = `/assets/images/${isBookmarked ? 'bookmarked' : 'bookmark'}.svg`;

  return (
    <Box
      onClick={bookmarkAction}
      cursor="pointer"
      border="1px solid"
      borderRadius="6px"
      p={{ base: '2px 8px', md: '4px 12px' }}
      m="0px 8px"
      w={{ base: '140px', md: '148px' }}
      h={{ base: '28px', md: '32px' }}
    >
      <Center h="100%">
        {isLoading ? (
          <Spinner size={{ base: 'xs', md: 'md' }} />
        ) : (
          <Flex>
            <Image alt={text} src={imageSrc} mr={2} />
            {text}
          </Flex>
        )}
      </Center>
    </Box>
  );
};

export { BookmarkButton };
